import React from 'react';
import { SortDirection } from 'ag-grid-community';
import { CustomHeaderTemplateProps } from '../models/atGridProps';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const sortStates: (SortDirection | null)[] = [null, 'asc', 'desc'];

export const CustomHeaderTemplate = (props: CustomHeaderTemplateProps) => {
  const [sortIndex, setSortIndex] = React.useState<number>(0);

  const onSortRequested = (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => {
    const nextIndex = (sortIndex + 1) % sortStates.length;
    setSortIndex(nextIndex);
    const order = sortStates[nextIndex];
    props.setSort(order, event.shiftKey);
  };

  const sortOptions = (
    <>
      {sortStates[sortIndex] === 'asc' && (
        <ArrowUpwardIcon aria-hidden="true" sx={{ fontSize: 18 }} />
      )}
      {sortStates[sortIndex] === 'desc' && (
        <ArrowDownwardIcon aria-hidden="true" sx={{ fontSize: 18 }} />
      )}
      {sortStates[sortIndex] === null && <></>}
    </>
  );

  return (
    <div className="ag-cell-label-container" role="presentation">
      <div
        data-ref="eLabel"
        className="ag-header-cell-label"
        role="presentation"
        onClick={(event) => onSortRequested(event)}
        onTouchEnd={(event) => onSortRequested(event)}
      >
        <span data-ref="eText" className="ag-header-cell-text">
          {props.customHeader ?? props.displayName}
        </span>
        {sortOptions}
      </div>
    </div>
  );
};
