import React, { useEffect, useState } from 'react';
import { useReportFilterStore } from '../../common/components/ReportFilters/hooks/reportFiltersStore';
import { useRefreshState } from '../../common/components/ReportFilters/hooks';
import { IReportFilters } from '../../common/components/ReportFilters/models/IReportFilters';
import { WorkingHoursReportHeaderPlayground } from '../components/WorkingHoursReportHeaderPlayground';
import { TabPanel } from '../../common/components/Tabs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { navA11yTabProps } from '../../common/utils/a11y.utils';
import { WorkingHoursReportSections } from '../../reports/working-hours/models/WorkingHoursTypes';
import { FlexibleWorkReport } from '../../reports/working-hours/components/FlexibleWorkReport';
import { ScheduledWorkReport } from '../../reports/working-hours/components/ScheduledWorkReport';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export const WorkingHoursReportPlaygroundView = () => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const [view, setView] = useState<
    WorkingHoursReportSections.SUMMARY | WorkingHoursReportSections.DETAILED
  >(WorkingHoursReportSections.SUMMARY);

  const { refreshTimestamp, handleRefresh } = useRefreshState();
  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

  useEffect(() => {
    if (refreshTimestamp) {
      setIsRefreshing(true);
      setTimeout(() => {
        setIsRefreshing(false);
      }, 5000);
    }
  }, [refreshTimestamp]);

  const handleTabChange = (
    event: React.ChangeEvent<object>,
    newTab: number
  ) => {
    setTab(newTab);
  };

  const handleToggleChange = (e) => {
    const targetMode: WorkingHoursReportSections = e.target.value;
    setView(targetMode);
  };

  return (
    <>
      <WorkingHoursReportHeaderPlayground
        reportFilters={reportFilters}
        onRefresh={handleRefresh}
        isRefreshing={isRefreshing}
        refreshTimestamp={refreshTimestamp}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 2
        }}
      >
        <Box>
          <Tabs component="div" value={tab} onChange={handleTabChange}>
            <Tab
              label="Flexible Work"
              {...navA11yTabProps(0)}
              //TODO: Add onClick to navigate to the correct tab url
              // onClick={() => {
              //   history.push('app.reports.workingHours.tab', {
              //     activeTab: 0
              //   });
              // }}
            />
            <Tab
              label="Schedule Adherence"
              {...navA11yTabProps(1)}
              //TODO: Add onClick to navigate to the correct tab url
              // onClick={() => {
              //   history.push('app.reports.workingHours.tab', {
              //     activeTab: 1
              //   });
              // }}
            />
          </Tabs>
        </Box>
        <Box>
          <ToggleButtonGroup
            color="primary"
            value={view}
            exclusive
            onChange={handleToggleChange}
            aria-label="Platform"
          >
            <ToggleButton value={WorkingHoursReportSections.SUMMARY}>
              Summary
            </ToggleButton>
            <ToggleButton value={WorkingHoursReportSections.DETAILED}>
              Detailed
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
      <Box sx={{ mt: 1 }}>
        <TabPanel
          value={tab}
          index={0}
          id={'nav-tabpanel-0'}
          key={'nav-tabpanel-0'}
        >
          <FlexibleWorkReport view={view} />
        </TabPanel>
        <TabPanel
          value={tab}
          index={1}
          id={'nav-tabpanel-1'}
          key={'nav-tabpanel-1'}
        >
          <ScheduledWorkReport view={view} />
        </TabPanel>
      </Box>
    </>
  );
};
