import { FeatureFlag } from '../../../../_reactivtrak/src/common/enums/FeatureFlag';
import authorizationService from '../../../../_reactivtrak/src/common/helpers/authorization';
import atInsightsNavigationBarTemplate from 'views/reports/insights/atInsightsNavigationBar.html?raw';
import lodash from 'lodash';
import { getInsightsSettingsStore } from '../../../../_reactivtrak/src/common/stores/insightsSettingsStore';

angular.module('app').directive('atInsightsNavigationBar', function () {
    return {
        restrict: 'E',
        scope: {},
        template: atInsightsNavigationBarTemplate,
        controller: atInsightsNavigationBarCtrl
    };
});

atInsightsNavigationBarCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    'envConfig',
    'localStorageService',
    'AccountSettingsService'
];

function atInsightsNavigationBarCtrl(
    $scope,
    $rootScope,
    $state,
    envConfig,
    localStorageService,
    AccountSettingsService
) {
    let features = null;
    const INSIGHTS_SUB_NAV_FEATURE = `${AccountSettingsService.account}_insights_sub_nav_features`;

    function hasInsightsSubNavFeature(feature) {
        return features && features[feature];
    }

    function getFilteredSubNavigation(menuItem) {
        const subNavs = menuItem.getSubNavigation();

        const filteredSubNavs = [];
        lodash.forEach(subNavs, function (subnav) {
            const subNavRules = subnav.rules;
            if (!subNavRules) {
                filteredSubNavs.push(subnav);
            } else {
                const subNavMenuFeature = subNavRules.map(function (rule) {
                    return rule.value;
                });
                let showSubNav = true;
                lodash.forEach(subNavMenuFeature, function (feature) {
                    showSubNav = showSubNav && hasInsightsSubNavFeature(feature);
                });
                if (showSubNav) {
                    filteredSubNavs.push(subnav);
                }
            }
        });
        return filteredSubNavs;
    }

    function setTabs() {
        // Get current page's sub-navigation
        const menuItem = $rootScope.sidebar.getMenuItem($state.current.name, $state.params);
        const navigationBar = menuItem && getFilteredSubNavigation(menuItem);

        // Map navigation options to tab options
        function mapNavigationToTabs(options) {
            if (!options || options.length <= 1) {
                return null;
            }

            return lodash.map(options, function (o) {
                return {
                    heapId: 'id_' + o.id,
                    text: o.subPageId,
                    value: o.label.toUpperCase()
                };
            });
        }

        // Handle tab click
        function handleViewChange(selection) {
            $state.go($state.current.name, { subPageId: selection });
        }

        // Define tab directive properties
        $scope.tab = $state.params.subPageId;
        $scope.tabOptions = {
            tabs: mapNavigationToTabs(navigationBar),
            onChange: handleViewChange
        };
    }

    $scope.back = function () {
        window.history.back();
    };

    const sidebarSubscription = $rootScope.sidebar.menu.subscribe(function () {
        const menuItem = $rootScope.sidebar.getMenuItem($state.current.name, $state.params);

        if (menuItem && menuItem.getSubNavigation()) {
            const subNavFeatures = localStorageService.get(INSIGHTS_SUB_NAV_FEATURE, 'sessionStorage');
            if (subNavFeatures) {
                features = subNavFeatures;
            } else if (authorizationService.hasFeature(FeatureFlag.InsightsAvailable)) {
                getInsightsSettingsStore()
                    .then(function (result) {
                        features = {
                            calendarIntegrated: result?.calendarIntegrated
                        };

                        if (features) {
                            localStorageService.set(INSIGHTS_SUB_NAV_FEATURE, features, 'sessionStorage');
                        }
                    })
                    .catch(function (e) {
                        console.error('ActivTrak Error: Error retrieving Insights subnav features', e);
                    })
                    .finally(function () {
                        setTabs();
                    });
                return;
            }
        }

        setTabs();
    });

    $scope.$on('$destroy', function () {
        sidebarSubscription.unsubscribe();
    });
}
