import { createTheme } from '@mui/material';
import {
  primaryAqua,
  secondaryAqua,
  gray5,
  gray6,
  primaryColor,
  primaryFontSize,
  regularFontWeight,
  heading6FontSize,
  tooltipFontSize,
  buttonFontSize,
  mediumFontWeight,
  primaryFontFamily,
  heading1FontSize,
  heading2FontSize,
  heading3FontSize,
  heading4FontSize,
  heading5FontSize,
  primaryBlue,
  gray15,
  gray13,
  aqua5,
  aqua6,
  gray14,
  aqua3,
  aqua4,
  breakpoints,
  linkHoverColor,
  buttonTextColor,
  headerColor,
  disabledTextColor,
  fontColor700,
  fontColor800,
  fontColor600,
  smallFontSize,
  fontColorError,
  notifSuccess,
  notifInfo,
  gray0,
  notifWarn,
  notifError,
  secondaryFontSize,
  gray10,
  gray7,
  gray3
} from '../constants';

const baseStyles = {
  fontFamily: primaryFontFamily,
  fontWeightRegular: regularFontWeight,
  fontSize: parseInt(primaryFontSize),
  lineHeight: 1.5
};

export const getTheme = () => {
  return createTheme({
    palette: {
      primary: {
        main: primaryAqua,
        light: secondaryAqua,
        dark: primaryAqua
      },
      text: {
        primary: primaryColor,
        secondary: gray6
      }
    },
    typography: baseStyles,
    breakpoints: {
      values: breakpoints
    },
    components: {
      MuiButton: {
        styleOverrides: {
          outlinedPrimary: {
            color: buttonTextColor,
            borderColor: gray13,
            '&:hover': {
              backgroundColor: gray15,
              color: primaryColor,
              borderColor: primaryColor
            },
            '&:focus': {
              backgroundColor: gray15,
              color: primaryColor,
              borderColor: primaryColor
            },
            '&.Mui-disabled': {
              color: gray13,
              borderColor: gray13,
              backgroundColor: gray15,
              opacity: 1,
              '&:hover': {
                cursor: 'not-allowed'
              }
            }
          },
          outlinedSecondary: {
            color: primaryAqua,
            backgroundColor: '#ffffff',
            borderColor: primaryAqua,
            '&:hover': {
              backgroundColor: aqua5,
              borderColor: primaryAqua
            },
            '&:focus': {
              backgroundColor: aqua6,
              borderColor: primaryAqua
            },
            '&.Mui-disabled': {
              color: primaryAqua,
              backgroundColor: aqua5,
              borderColor: primaryAqua,
              opacity: 0.5,
              '&:hover': {
                cursor: 'not-allowed'
              }
            }
          },

          textPrimary: {
            color: primaryBlue,
            backgroundColor: '#ffffff',
            borderColor: '#ffffff',
            '&:hover': {
              color: linkHoverColor,
              backgroundColor: '#ffffff'
            },
            '&:focus': {
              backgroundColor: '#ffffff'
            },
            '&.Mui-disabled': {
              color: gray13,
              opacity: 1,
              '&:hover': {
                cursor: 'not-allowed'
              }
            }
          },
          textSecondary: {
            color: primaryColor,
            '&:hover': {
              backgroundColor: gray15,
              color: primaryColor
            },
            '&:focus': {
              backgroundColor: gray15,
              color: primaryColor
            },
            '&.Mui-disabled': {
              color: primaryColor,
              background: 'none',
              '&:hover': {
                cursor: 'not-allowed'
              }
            }
          },
          containedPrimary: {
            color: buttonTextColor,
            backgroundColor: primaryAqua,
            borderColor: primaryAqua,
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: aqua3,
              boxShadow: 'none'
            },
            '&:focus': {
              backgroundColor: aqua4
            },
            '&.Mui-disabled': {
              color: disabledTextColor,
              borderColor: gray14,
              backgroundColor: gray14,
              opacity: 1,
              '&:hover': {
                cursor: 'not-allowed'
              }
            }
          },
          root: {
            fontWeight: mediumFontWeight,
            letterSpacing: '1.25px',
            textTransform: 'none',
            color: buttonTextColor,
            padding: '8px 16px',
            lineHeight: '16px',
            fontSize: buttonFontSize,
            '&.Mui-disabled': {
              pointerEvents: 'auto',
              cursor: 'not-allowed',
              opacity: 0.4,
              color: disabledTextColor
            }
          }
        }
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            '&.MuiRadio-root.Mui-disabled': {
              color: primaryColor,
              opacity: 0.65
            },
            '&.MuiToggleButton-root': {
              height: '32px',
              fontWeight: 400,
              textTransform: 'none',
              fontSize: secondaryFontSize,
              lineHeight: '18px',
              letterSpacing: '0.25px',
              color: gray6,
              backgroundColor: gray10,
              border: 'none',
              '&:hover': {
                color: buttonTextColor,
                backgroundColor: gray3,
                boxShadow: `0 2px 2px 0 rgba(0, 0, 0, 0.14),0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)`
              },
              '&.Mui-selected': {
                backgroundColor: gray7,
                color: '#FFF',
                '&:hover': {
                  backgroundColor: gray7,
                  color: '#FFF',
                  boxShadow: `0 2px 2px 0 rgba(0, 0, 0, 0.30),0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)`
                }
              }
            }
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            color: primaryColor,
            fontSize: primaryFontSize
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: primaryColor,
            fontSize: primaryFontSize,
            '&.MuiDialogTitle-root': {
              fontSize: heading6FontSize,
              paddingTop: '24px',
              paddingBottom: 0,
              paddingRight: '24px',
              paddingLeft: '24px'
            },
            '&.MuiLink-root': {
              color: primaryBlue,
              textDecoration: 'none',
              cursor: 'pointer',
              '&[disabled]': {
                pointerEvents: 'none',
                color: gray13
              },
              '&:hover': {
                color: linkHoverColor
              }
            }
          },
          body1: {
            fontSize: primaryFontSize
          },
          h1: {
            color: headerColor,
            fontSize: heading1FontSize,
            fontWeight: mediumFontWeight,
            letterSpacing: '-1.5px',
            lineHeight: 1.2
          },
          h2: {
            color: headerColor,
            fontSize: heading2FontSize,
            fontWeight: mediumFontWeight,
            letterSpacing: '-0.5px',
            lineHeight: 1.2
          },
          h3: {
            color: headerColor,
            fontSize: heading3FontSize,
            fontWeight: mediumFontWeight,
            lineHeight: 1.2
          },
          h4: {
            color: headerColor,
            fontSize: heading4FontSize,
            fontWeight: mediumFontWeight,
            lineHeight: 1.2
          },
          h5: {
            color: headerColor,
            fontSize: heading5FontSize,
            fontWeight: mediumFontWeight,
            lineHeight: 1.2
          },
          h6: {
            color: headerColor,
            fontSize: heading6FontSize,
            fontWeight: mediumFontWeight,
            letterSpacing: '0.5px',
            lineHeight: 1.2
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            color: primaryColor,
            fontSize: primaryFontSize
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            '+.MuiDialogContent-root': {
              paddingTop: '24px !important'
            },
            paddingRight: '24px',
            paddingLeft: '24px'
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            paddingTop: '24px',
            paddingBottom: '40px',
            paddingRight: '24px',
            paddingLeft: '24px'
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingBottom: '24px',
            paddingRight: '24px',
            paddingLeft: '24px'
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            '& .MuiInputLabel-root.MuiInputLabel-sizeSmall': {
              top: '-3px'
            },
            '& .MuiInputLabel-root.MuiInputLabel-shrink': {
              top: '2px'
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: primaryAqua
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              color: fontColor800,
              opacity: 0.38
            },
            '& .MuiFormHelperText-root': {
              color: fontColor700
            },
            '& .Mui-error.MuiFormHelperText-root': {
              color: fontColorError
            },
            '& .Mui-error.MuiFormLabel-root': {
              color: fontColorError
            }
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: fontColor700,
            fontSize: primaryFontSize,
            fontWeight: regularFontWeight,
            '.Mui-error input': {
              color: fontColorError
            }
          },
          input: {
            '&.MuiOutlinedInput-input': {
              padding: '10px 16px'
            }
          },
          sizeSmall: {
            input: {
              '&.MuiOutlinedInput-input': {
                paddingTop: '6px',
                paddingBottom: '6px'
              }
            },
            '& .MuiSelect-select.MuiOutlinedInput-input': {
              paddingTop: '6px',
              paddingBottom: '6px'
            }
          },
          adornedStart: {
            '& .MuiOutlinedInput-input.MuiOutlinedInput-input': {
              paddingLeft: 0
            }
          },
          adornedEnd: {
            '& .MuiOutlinedInput-input.MuiOutlinedInput-input': {
              paddingRight: 0
            }
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-root': {
              color: fontColor800
            },
            '& .MuiInputLabel-root': {
              color: fontColor700,
              top: '-5px'
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: primaryColor
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              color: primaryColor,
              borderColor: primaryAqua
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              color: fontColor800,
              borderColor: fontColor600,
              opacity: 0.38
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              color: fontColorError,
              borderColor: fontColorError
            }
          },
          notchedOutline: {
            borderColor: gray13
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: smallFontSize,
            '&.Mui-focused': {
              color: primaryColor
            },
            '.Mui-error': {
              color: fontColorError
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: primaryFontSize,
            color: fontColor700,
            top: '-5px'
          },
          sizeSmall: {
            top: '-3px'
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: primaryColor
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              paddingTop: '6px',
              paddingBottom: '7px'
            },
            '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
              paddingTop: '4px',
              paddingBottom: '3px'
            },
            '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
              paddingTop: '3px',
              paddingBottom: '4px'
            }
          },
          listbox: {
            '& .MuiAutocomplete-option[aria-selected="true"]': {
              backgroundColor: 'transparent',

              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              },

              '&.Mui-focused': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              color: primaryColor,
              opacity: 0.65,
              cursor: 'not-allowed',
              '& .MuiTypography-root': {
                opacity: 0.65
              }
            },
            '& .MuiFormControlLabel-label.Mui-disabled': {
              color: primaryColor
            }
          }
        }
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            '&.Mui-checked': {
              color: primaryColor
            },
            '&:hover': {
              backgroundColor: 'rgba(68, 68, 68, 0.04)'
            }
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: primaryColor,
            '&.Mui-disabled': {
              color: primaryColor,
              opacity: 0.65
            }
          },
          colorPrimary: {
            color: primaryColor,
            '&.Mui-disabled': {
              color: primaryColor,
              opacity: 0.65
            }
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: primaryFontSize,
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
            },
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
              }
            }
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: buttonTextColor
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: primaryColor,
            minWidth: 'auto',
            bottom: '24px',
            transition: 'none'
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: 'auto',
            minHeight: 'auto',
            padding: '2px 0 1px',
            marginLeft: '10px',
            marginRight: '10px',

            '&:first-of-type': {
              marginLeft: 0
            },
            color: `${gray5} !important`,
            fontSize: primaryFontSize,
            '&.Mui-selected': {
              color: `${primaryColor} !important`
            },
            '&:hover': {
              color: `${primaryColor} !important`,
              opacity: 1
            },
            '&:focus': {
              color: `${primaryColor} !important`
            },
            '&.MuiTab-textColorInherit.Mui-disabled': {
              opacity: 1
            }
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: tooltipFontSize
          }
        }
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            color: primaryColor
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
            '&.Mui-disabled': {
              cursor: 'not-allowed'
            },
            '&.Mui-disabled > .MuiInputBase-root >.MuiSelect-root': {
              cursor: 'not-allowed'
            },
            '&.Mui-disabled > .MuiListItemIcon-root': {
              cursor: 'not-allowed'
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
            },
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
              }
            }
          }
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
            '&.Mui-disabled': {
              cursor: 'not-allowed'
            },
            '&.Mui-disabled > .MuiInputBase-root >.MuiSelect-root': {
              cursor: 'not-allowed'
            },
            '&.Mui-disabled > .MuiListItemIcon-root': {
              cursor: 'not-allowed'
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
            },
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
              }
            }
          }
        }
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: '24px 24px 0'
          }
        }
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '24px'
          }
        }
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: '0px 0px 24px 24px'
          }
        }
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            fontWeight: mediumFontWeight,
            a: {
              color: `${primaryColor} !important`,
              textDecoration: 'underline !important',
              '&:hover': {
                color: primaryColor,
                textDecoration: 'underline'
              }
            },
            '& .MuiAlert-icon': {
              color: primaryColor
            },
            '&.MuiAlert-outlined': {
              borderWidth: '2px'
            },
            '& .MuiAlert-action': {
              '& .MuiButton-root': {
                color: buttonTextColor
              },
              '& .MuiIconButton-root': {
                color: buttonTextColor
              }
            },
            '&.MuiAlert-colorSuccess': {
              backgroundColor: notifSuccess,
              '&.MuiAlert-outlinedSuccess': {
                backgroundColor: 'transparent',
                borderColor: notifSuccess
              }
            },
            '&.MuiAlert-colorInfo': {
              backgroundColor: notifInfo,
              color: `${gray0} !important`,
              a: {
                color: `${gray0} !important`,
                textDecoration: 'underline !important',
                '&:hover': {
                  color: gray0,
                  textDecoration: 'underline'
                }
              },
              '& .MuiTypography-root': {
                color: `${gray0} !important`
              },
              '& .MuiAlert-icon': {
                color: gray0
              },
              '& .MuiAlert-action': {
                '& .MuiButton-root': {
                  color: gray0
                },
                '& .MuiIconButton-root': {
                  color: gray0
                }
              },
              '&.MuiAlert-outlinedInfo': {
                backgroundColor: 'transparent',
                borderColor: notifInfo,
                color: `${primaryColor} !important`,
                a: {
                  color: `${primaryColor} !important`,
                  textDecoration: 'underline !important',
                  '&:hover': {
                    color: primaryColor,
                    textDecoration: 'underline'
                  }
                },
                '& .MuiTypography-root': {
                  color: `${primaryColor} !important`
                },
                '& .MuiAlert-icon': {
                  color: primaryColor
                },
                '& .MuiAlert-action': {
                  '& .MuiButton-root': {
                    color: buttonTextColor
                  },
                  '& .MuiIconButton-root': {
                    color: buttonTextColor
                  }
                }
              }
            },
            '&.MuiAlert-colorWarning': {
              backgroundColor: notifWarn,
              '&.MuiAlert-outlinedWarning': {
                backgroundColor: 'transparent',
                borderColor: notifWarn
              }
            },
            '&.MuiAlert-colorError': {
              backgroundColor: notifError,
              '&.MuiAlert-outlinedError': {
                backgroundColor: 'transparent',
                borderColor: notifError
              }
            }
          }
        }
      }
    }
  });
};
