import { Role } from '../../../../../../enums';
import { BundleFlag } from '../../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../../models/IRoute';

export const appSettingsIdentity: IRoute = {
  name: 'app.settings.identity',
  stateDefinition: {
    url: '/settings/users',
    template: '<settings-users></settings-users>',
    data: { pageTitle: 'Users' }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin],
    read: [Role.SupportIntermediate],
    bundleFlags: [BundleFlag.UserIdentityConfiguration]
  }
};

export default appSettingsIdentity;
