import React from 'react';
import authorization from '../../../common/helpers/authorization';
import { WorkingHoursReportNewGridHeaderProps } from '../models/WorkingHoursTypes';
import { generateBody } from '../../../common/components/ReportFilters/utils/generateParameters';
import { ReportType } from '../../../common/enums/ReportType';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import { setReportFilters } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { UserComputerFilter } from '../../../common/components/ReportFilters/components/UserComputerFilter';
import { DateRangeFilter } from '../../../common/components/ReportFilters/components/DateRangeFilter';
import { RefreshButton } from '../../../common/components/ReportFilters/components/RefreshButton';
import { AcademyButton } from '../../../common/components/ReportFilters/components/AcademyButton';
import { AsyncExportButton } from '../../../common/components/ReportFilters/components/AsyncExportButton';
import { ExportButtonContainer } from '../../../common/components/ReportFilters/components/ExportButton';
import { IExportReportParams } from '../../../common/models/IExport';
import { GridColumnsFilter } from '../../../common/components/ReportFilters/components/GridColumnsFilter';
import Box from '@mui/material/Box';
import {
  ReportHeaderInline,
  ReportHeaderLeftColumn,
  ReportHeaderRightColumn
} from '../../../common/styles/app.component.styles';

export const WorkingHoursReportNewGridHeader = (
  props: WorkingHoursReportNewGridHeaderProps
) => {
  const {
    reportFilters,
    isRefreshing,
    selectedColumns,
    onSubmitColumns,
    onRestoreDefaults,
    onRefresh
  } = props;
  const { dates, users } = reportFilters;

  const ignoreDateRestrictions: boolean = authorization.isSupportOrSuperAdmin();

  const hasAsyncExport: boolean = authorization.hasFeature(
    BundleFlag.WorkingHoursReportExport
  );

  const hasLargeExport: boolean = authorization.hasFeature(
    BundleFlag.LargeExport
  );

  const createExportPayload = (): IExportReportParams => {
    const { from, to, userType, userMode, userId } = generateBody(
      reportFilters,
      {}
    );

    return {
      type: ReportType.WorkingHours,
      startDate: from,
      endDate: to,
      userType,
      userMode,
      userId
    };
  };

  return (
    <Box>
      <ReportHeaderLeftColumn>
        <ReportHeaderInline>
          <DateRangeFilter
            dates={dates}
            onSubmitFilter={setReportFilters}
            ignoreRestrictions={ignoreDateRestrictions}
          />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <UserComputerFilter onSubmitFilter={setReportFilters} users={users} />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <RefreshButton onRefresh={onRefresh} isRefreshing={isRefreshing} />
        </ReportHeaderInline>
      </ReportHeaderLeftColumn>
      <ReportHeaderRightColumn>
        <ReportHeaderInline sx={{ mr: { xs: 0, md: 1 } }}>
          <AcademyButton />
        </ReportHeaderInline>
        <ReportHeaderInline sx={{ display: { xs: 'none', md: 'block' } }}>
          <GridColumnsFilter
            columns={selectedColumns}
            onSubmit={onSubmitColumns}
            onRestoreDefaults={onRestoreDefaults}
          />
        </ReportHeaderInline>
        <ReportHeaderInline sx={{ display: { xs: 'none', md: 'block' } }}>
          {hasAsyncExport ? (
            <AsyncExportButton
              reportType={ReportType.WorkingHours}
              createExportPayload={createExportPayload}
              showExportsLimitedTooltip={true}
              exportableItemCount={hasLargeExport ? '50,000' : '10,000'}
            />
          ) : (
            <ExportButtonContainer />
          )}
        </ReportHeaderInline>
      </ReportHeaderRightColumn>
    </Box>
  );
};
