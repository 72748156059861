import { setReportFilters } from '../../../../_reactivtrak/src/common/components/ReportFilters/hooks/reportFiltersStore';
import { getFormattedFromTimestamp } from '../../../../_reactivtrak/src/common/components/ReportFilters/utils/dateRange.utils';
import { getAccountSettings } from '../../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';
import { userServiceFunctions } from '../../../../_app/serviceFunctions/userServiceFunctions';
import messageModalControllerTemplate from 'views/modals/messageModal.html?raw';
import deleteSelectedScreenshotsModalControllerTemplate from 'views/modals/deleteSelectedScreenshotsModal.html?raw';
import { generateParameters } from '../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';

// Register report component
import './screenshots.component.js';
import { fetchScreenshotRedactionSettings } from '../../../../_reactivtrak/src/common/stores/screenshotRedactionSettingsStore';

angular.module('app').controller('ScreenshotsCtrl', ScreenshotsCtrl);

ScreenshotsCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    '$timeout',
    'customUibModal',
    'accountApiHelper',
    'localStorageService',
    'messagesService',
    'screenshotsService',
    'pageSizeService',
    'exportInfoService',
    'browserServiceFunctions',
    'authorizationService',
    'loginService',
    'atHelperFunctions'
];

function ScreenshotsCtrl(
    $rootScope,
    $scope,
    $state,
    $timeout,
    customUibModal,
    accountApiHelper,
    localStorageService,
    msg,
    screenshotsService,
    pageSizeService,
    exportInfoService,
    browserServiceFunctions,
    authorizationService,
    loginService,
    atHelperFunctions
) {
    const accountSettings = getAccountSettings();

    $scope.screenshotsService = screenshotsService;
    $scope.safeSearchFeatureFlag = accountSettings.safeSearchFeatureFlag;
    $scope.pageMinImages = browserServiceFunctions.isMobileAgent() ? 16 : 32;
    $scope.allScreenshotsCount = 0;
    $scope.isBulkSelected = false;
    $scope.isAllChecked = false;
    $scope.isIndeterminate = false;
    $scope.selectedScreenshotsForDelete = [];
    $scope.isDirty = false;

    const rowSize = screenshotsService.getScreenshotsRowSize();
    const storedOrDefaultPageSize = pageSizeService.loadPageSize('screenshots-master', $scope.pageMinImages);
    const pageSize = screenshotsService.getClosestMultipleOfRowSize(storedOrDefaultPageSize, rowSize);
    const pageSizes = screenshotsService.getPageSizes(rowSize);
    let cacheKey;
    $scope.reportFilters = {};

    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(levels, 'app.screenshots.history');
    };

    const getAllChecked = function () {
        return (
            $scope.images &&
            $scope.images.every(function (item) {
                return item.selected;
            })
        );
    };

    const getIsIndeterminate = function () {
        if ($scope.isAllChecked) return false;

        return (
            $scope.images &&
            $scope.images.some(function (item) {
                return item.selected;
            })
        );
    };

    //These states control what the bulk screenshot selection checkbox looks like
    const updateCheckboxStatuses = function () {
        $scope.isAllChecked = getAllChecked();
        $scope.isIndeterminate = getIsIndeterminate();
        $scope.isBulkSelected = $scope.isAllChecked || $scope.isIndeterminate;
        $scope.isDirty = $scope.selectedScreenshotsForDelete.length > 0;
    };

    $scope.toggleScreenshotsToDelete = function (id) {
        //if it's added to 'selectedScreenshotsForDelete', remove it
        if ($scope.selectedScreenshotsForDelete.includes(id)) {
            $scope.selectedScreenshotsForDelete = $scope.selectedScreenshotsForDelete.filter(function (item) {
                return item !== id;
            });
        }
        //else add it to 'selectedScreenshotsForDelete'
        else {
            $scope.selectedScreenshotsForDelete.push(id);
        }

        updateCheckboxStatuses();
    };

    $scope.toggleAllCheckboxes = function () {
        const isSelectedValue = !(getAllChecked() || getIsIndeterminate());
        for (let i = 0; i < $scope.images.length; i++) {
            $scope.images[i].selected = isSelectedValue;
            if (isSelectedValue) $scope.selectedScreenshotsForDelete.push($scope.images[i].id);
        }
        if (!isSelectedValue) $scope.selectedScreenshotsForDelete = [];

        updateCheckboxStatuses();
    };

    const getProductivityLabel = function (status) {
        switch (status) {
            case 1:
                return 'Productive';
            case -1:
                return 'Unproductive';
            default:
                return 'Undefined';
        }
    };

    fetchScreenshotRedactionSettings();

    const source = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                if (Object.keys($scope.reportFilters).length === 0) {
                    options.success({ data: [], total: 0, pagerTotal: 0 });
                    return;
                }

                $scope.loading = true;

                const formattedTimeStamp = $scope.fromTimestamp
                    ? getFormattedFromTimestamp($scope.fromTimestamp, accountSettings.currentIanaTimeZone)
                    : null;
                const resetSlider =
                    options.data.refresh ||
                    !options.data.sort ||
                    !options.data.sort.length ||
                    options.data.sort[0].field !== 'time';
                if (resetSlider) {
                    setReportFilters({ fromTimeStamp: null });
                } else if (formattedTimeStamp) {
                    options.data.slider = formattedTimeStamp;
                }

                $scope.selectedScreenshotsForDelete = [];

                screenshotsService
                    .getScreenshots(generateParameters($scope.reportFilters, { showProductivity: true }), {
                        params: options.data
                    })
                    .then(function (result) {
                        $scope.loading = false;
                        cacheKey = result.data.cacheKey;
                        result?.data?.data?.forEach((r) => {
                            r.time = r.time.includes('T')
                                ? atHelperFunctions.formatDate(atHelperFunctions.convertTimezone(r.time), {
                                      span: false
                                  })
                                : r.time;
                        });
                        options.success(result.data);

                        //pass data state to react wrapper to control slider
                        $rootScope.$broadcast('hasDataCheck', { hasData: result.data?.data?.length > 0 });
                    })
                    .catch(function (result) {
                        $scope.loading = false;
                        options.error(result);
                        $scope.$emit('showNotification', {
                            message: msg.get('screenshotsRetrievingError'),
                            color: 'danger'
                        });
                    });
            }
        },
        schema: {
            data: 'data',
            total: 'total',
            pagerTotal: 'sliderCount'
        },
        sort: {
            field: 'time',
            dir: localStorageService.get('screenshot-newest-first') ? 'desc' : 'asc'
        },
        pageSize: pageSize,
        serverPaging: true,
        serverSorting: true,
        serverFiltering: true,
        change: function () {
            // TODO: Fix the DTO structure...
            const data = this.data();
            const images = [];

            function addTokenToImageUrl(baseUrl) {
                const trimmedUrl = baseUrl.split('?')[0];
                return trimmedUrl + '?token=' + userServiceFunctions.getEncodedUserToken();
            }

            for (let index = 0; index < data.length; index++) {
                const image = data[index];
                image.index = index;
                image.selected = false;
                image.productivityLabel = getProductivityLabel(image.prod);
                const imageThumbnail = image.image;
                if (imageThumbnail) {
                    image.image = imageThumbnail.includes('data:image/png')
                        ? imageThumbnail
                        : addTokenToImageUrl(imageThumbnail);
                }
                const imageFullSize = image.fullsize;
                if (imageFullSize) {
                    image.fullsize = addTokenToImageUrl(imageFullSize);
                }
                if (imageFullSize) {
                    images.push(image);
                }
            }

            $scope.images = images;
            $scope.screenshotViewerConfig = {
                dataSource: source,
                images: images
            };
            updateCheckboxStatuses();
        },
        entryLimit: 10000
    });

    $scope.source = source;

    $scope.pagerOptions = {
        dataSource: source,
        refresh: true,
        pageSizes: pageSizes,
        buttonCount: 5,
        messages: {
            itemsPerPage: msg.get('itemsPerPage', 'screenshots'),
            display: msg.get('itemsDisplay', 'screenshots'),
            empty: msg.get('noItemsToDisplay', 'screenshots')
        }
    };

    // Check if page is dirty before state change
    $scope.$on('$stateChangeStart', function (event, target, data) {
        // If scope is dirty and user did not log out
        // then ask for navigation confirmation.
        if ($scope.isDirty && loginService.isLoggedIn()) {
            event.preventDefault();

            const modal = customUibModal.open({
                animation: false,
                template: messageModalControllerTemplate,
                controller: 'messageModalController',
                windowClass: 'centered-modal',
                resolve: {
                    messageData: {
                        messageTitle: msg.get('unsavedChanges'),
                        messageBody: msg.get('leaveAction'),
                        confirmLabel: msg.get('discardChanges'),
                        secondActionLabel: msg.get('saveChanges')
                    }
                }
            });

            modal.result.then(function (saveChanges) {
                if (saveChanges) {
                    $scope.deleteSelectedScreenshots(function (success) {
                        if (success) {
                            $timeout(function () {
                                $state.go(target.name, data);
                            }, 1000);
                        }
                    });
                } else {
                    $scope.isDirty = false;
                    $state.go(target.name, data);
                }
            });
        }
    });

    $scope.bindReport = function (filters) {
        $scope.reportFilters = filters ?? $scope.reportFilters;
        $scope.fromTimestamp = $scope.reportFilters?.fromTimestamp;
        pageSizeService.dataSourceReload(source);
    };

    $scope.deleteSelectedScreenshots = function () {
        if ($scope.selectedScreenshotsForDelete.length) {
            $scope.deleteModalInstance = customUibModal.open({
                template: deleteSelectedScreenshotsModalControllerTemplate,
                animation: false,
                windowClass: 'centered-modal',
                controller: 'deleteSelectedScreenshotsModalController',
                resolve: {
                    warningMessage: {
                        screenshotCount: $scope.selectedScreenshotsForDelete.length,
                        confirmTrackEvent: 'history-deleteSelected-confirm'
                    }
                }
            });

            $scope.deleteModalInstance.result.then(function () {
                screenshotsService
                    .deleteSelected($scope.selectedScreenshotsForDelete)
                    .success(function () {
                        pageSizeService.dataSourceReload(source);
                        $scope.$emit('showNotification', {
                            message: msg.get('selectedScreenshotsDeleted'),
                            color: 'success'
                        });
                    })
                    .error(function () {
                        $scope.$emit('showNotification', {
                            message: msg.get('dataDeletingError'),
                            color: 'danger'
                        });
                    });
            });
        } else {
            $scope.$emit('showNotification', {
                message: msg.get('noScreenshotsForDeletion'),
                color: 'danger'
            });
        }
    };

    exportInfoService.setExportInfo({
        mainDataSource: source,
        getCacheKey: function () {
            return cacheKey;
        },
        getSecondaryParameters: function () {
            return {
                filter: source.filter()
            };
        },
        getSortParameters: function () {
            return {
                sort: source.sort()
            };
        }
    });

    $timeout(function () {
        if (pageSizeService.setDropdownHandler) {
            pageSizeService.setDropdownHandler('screenshots-master');
        }
    });
}
