import { createStore } from 'zustand';
import { fetchAllExports, fetchExport } from '../utils/exportStore.utils';
import { EXPORT_STORE_DEFAULT } from '../constants/exportStoreDefault';
import { IExport, IExportStore } from '../models/IExport';
import { ExportStatus } from '../enums/ExportStatus';
import authorization from '../helpers/authorization';
import { Role } from '../enums';
import { BundleFlag } from '../enums/BundleFlag';

export const exportStore = createStore<IExportStore>(() => ({
  ...EXPORT_STORE_DEFAULT
}));

/**
 * Fetches the export from the API and sets it in the export store.
 */
export const fetchAllExportStatuses = async (): Promise<IExport[]> => {
  const hasAsyncExports =
    authorization.hasFeature(BundleFlag.TopUsersReportExport) ||
    authorization.hasFeature(BundleFlag.TopWebsitesReportExport) ||
    authorization.hasFeature(BundleFlag.TopApplicationsReportExport) ||
    authorization.hasFeature(BundleFlag.ActivityLogReportExport) ||
    authorization.hasFeature(BundleFlag.AlarmLogReportExport) ||
    authorization.hasFeature(BundleFlag.AuditLogReportExport) ||
    authorization.hasFeature(BundleFlag.WorkingHoursReportExport) ||
    authorization.hasFeature(BundleFlag.ScreenshotsHistoryReportExport) ||
    authorization.hasFeature(BundleFlag.VideosHistoryReportExport);

  if (
    authorization.hasAnyRole([Role.SupportBasic, Role.SuperAdmin]) ||
    !hasAsyncExports
  ) {
    return exportStore.getState().exports;
  }

  try {
    const response = await fetchAllExports();

    const current = exportStore.getState();
    exportStore.setState({ ...current, exports: response });
  } catch (error) {
    console.error('ActivTrak Error: Error fetching all export statuses', error);
    throw error;
  }

  return exportStore.getState().exports;
};

/**
 * Fetches the export from the API and sets it in the export store.
 */
export const fetchExportStatus = async (exportId: string): Promise<IExport> => {
  let exportStatus = null;

  try {
    exportStatus = await fetchExport(exportId);
    const current = exportStore.getState();
    const { exports } = current;
    let updateExports = [exportStatus];

    if (exports.length > 0) {
      updateExports = exports.map((e) =>
        e.id === exportId ? exportStatus : e
      );
    }

    exportStore.setState({ ...current, exports: updateExports });
  } catch (error) {
    console.error('ActivTrak Error: Error fetching export status', error);

    throw error;
  }

  return exportStatus;
};

/**
 * Returns the current export store state.
 */
export const getExportStore = (): IExportStore => exportStore.getState();

export const getExport = (exportId: string): IExport => {
  const current = exportStore.getState();
  return current.exports.find((e) => e.id === exportId);
};

export const addExport = (exportStatus: IExport): void => {
  const current = exportStore.getState();
  const { exports } = current;
  const updateExports = [...exports, exportStatus];

  exportStore.setState({ ...current, exports: updateExports });
};

export const getInProgressExport = (): IExport => {
  const current = exportStore.getState();
  return current.exports.find((e) => e.status === ExportStatus.InProgress);
};

export const getCompletedExport = (): IExport => {
  const current = exportStore.getState();
  return current.exports.find((e) => e.status === ExportStatus.Completed);
};

export const getErroredExport = (): IExport => {
  const current = exportStore.getState();
  return current.exports.find((e) => e.status === ExportStatus.Failed);
};

export const hasExportsInProgress = (): boolean =>
  Boolean(getInProgressExport());

export const hasExportsCompleted = (): boolean => Boolean(getCompletedExport());

export const hasExportsError = (): boolean => Boolean(getErroredExport());

export const setExportStatusInterval = (
  exportId,
  interval: NodeJS.Timeout
): void => {
  const current = exportStore.getState();
  const { exportStatusIntervals } = current;
  exportStatusIntervals[exportId] = interval;

  exportStore.setState({ ...current, exportStatusIntervals });
};

/**
 * Resets the export store to its default state.
 */
export const resetExportStore = (): void =>
  exportStore.setState(EXPORT_STORE_DEFAULT);
