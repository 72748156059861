import { FeatureFlag } from '../../../../_reactivtrak/src/common/enums/FeatureFlag';
import template from 'views/widgets/notificationMessage.html?raw';
import { getAccountPlanStore, isAccountPlanInTrial } from '../../../../_reactivtrak/src/common/stores/accountPlanStore';
import { screenshotRedactionSettingsStore } from '../../../../_reactivtrak/src/common/stores/screenshotRedactionSettingsStore';

angular.module('app').directive('notificationMessage', notificationMessage);

notificationMessage.$inject = ['$window'];

function notificationMessage($window) {
    return {
        restrict: 'E',
        scope: false,
        template: template,
        controller: [
            '$scope',
            'widgetsApiService',
            '$rootScope',
            'AccountSettingsService',
            '$state',
            'screenshotsRedactionService',
            'screenshotsSafeSearchService',
            'authorizationService',
            'notificationService',
            function (
                $scope,
                widgetsApiService,
                $rootScope,
                AccountSettingsService,
                $state,
                screenshotsRedactionService,
                screenshotsSafeSearchService,
                authorizationService,
                notificationService
            ) {
                if ($rootScope.isAccountCreationMode) {
                    return;
                }
                $scope.notificationAlerts = [];

                function hasUpgradeFeature() {
                    return (
                        authorizationService.hasRouteByName('app.account.upgrade') &&
                        !authorizationService.hasFeature(FeatureFlag.Reactivate)
                    );
                }

                $scope.hasReactivateFeature = function () {
                    return (
                        authorizationService.hasRouteByName('app.account.upgrade') &&
                        authorizationService.hasFeature(FeatureFlag.Reactivate)
                    );
                };

                $scope.showUpgradeMessages = function () {
                    return hasUpgradeFeature() || $scope.hasReactivateFeature();
                };

                // Add Alert Message to Array
                function createGenericAlert(type, style, message) {
                    $scope.notificationAlerts.push({
                        type: type,
                        message: message,
                        class: style,
                        show: false
                    });
                }

                function createUpgradeAlert(type, style, message) {
                    $scope.notificationAlerts.push({
                        type: type,
                        message: message,
                        class: style,
                        show: false,
                        showUpgradeLink: true
                    });
                }

                function createBillingAlert(type, style, message) {
                    $scope.notificationAlerts.push({
                        type: type,
                        message: message,
                        class: style,
                        show: false,
                        showBillingLink: true
                    });
                }

                function createDeleteAlert(type, style, deleteLabel, message) {
                    $scope.notificationAlerts.push({
                        type: type,
                        message: message,
                        class: style,
                        show: false,
                        showDeleteLink: true,
                        deleteLabel: deleteLabel
                    });
                }

                // Create Alert Messages
                createBillingAlert('showFailedPayment', 'alert-danger', 'We were unable to process your payment.');

                createDeleteAlert('showOverStorage', 'alert-danger', 'data');
                createDeleteAlert(
                    'showOverLimit',
                    'alert-danger',
                    'users',
                    'The user count is over your plan limit. Data is being recorded but not displayed.' +
                        (authorizationService.hasRouteByName('app.settings.doNotTrack')
                            ? ' To stop tracking specific users, please add them to the <a href="#/app/settings/doNotTrack">Do Not Track list</a>.'
                            : '')
                );

                createUpgradeAlert(
                    'dlpNearingLimit',
                    'alert-warning',
                    'Screenshot Redaction usage is over 80% of the plan limit set for this account. Once the limit is exceeded, no new screenshots will be collected. Increase your account Screenshot Redaction limit.'
                );
                createUpgradeAlert(
                    'dlpOverLimit',
                    'alert-danger',
                    'Screenshot Redaction usage has exceeded the plan limit set for this account and no new screenshots will be collected. Increase your account Screenshot Redaction limit.'
                );
                createUpgradeAlert(
                    'safeSearchNearingLimit',
                    'alert-warning',
                    'Screenshot Flagging usage is over 80% of the plan limit set for this account. Increase your account Screenshot Flagging limit.'
                );
                createUpgradeAlert(
                    'safeSearchOverLimit',
                    'alert-danger',
                    'Screenshot Flagging usage has exceeded the plan limit set for this account.  Increase your account Screenshot Flagging limit.'
                );

                createGenericAlert('maintenanceModeSet', 'alert-warning');
                createGenericAlert(
                    'msaAcceptanceRequired',
                    'alert-warning',
                    'This account has not accepted the MSA and can not use the account until the MSA has been accepted by an account admin.'
                );
                createGenericAlert('deleteInProgress', 'alert-warning', 'Delete is in progress.');
                createGenericAlert('chromebookInstallError', 'alert-danger');
                createGenericAlert(
                    'preAggregationInProgress',
                    'alert-info',
                    'Recent changes to your database require a re-sync of your data.  Reports may populate slower until the synchronization process is complete.'
                );

                // Update Alert Message Visibility
                function updateNotificationAlerts(type, show, message) {
                    var alert = $scope.notificationAlerts.find(function (a) {
                        return a.type === type;
                    });

                    alert.show = show;
                    alert.message = message || alert.message;
                }

                // Get Account Limits
                function updateLimitNotifications() {
                    if (!authorizationService.hasRole(authorizationService.roles.supportBasic)) {
                        widgetsApiService.getCompany().success(function (result) {
                            if (result.overlimit && $window.location.href.indexOf('blocking') >= 0) {
                                $scope.blockingPage = true;
                            }
                            var maxStorage = AccountSettingsService.maxStorage || 3;

                            const { planRemainingDays } = getAccountPlanStore();
                            updateNotificationAlerts(
                                'showOverLimit',
                                result.overlimit && !(isAccountPlanInTrial() && planRemainingDays <= 0)
                            );
                            updateNotificationAlerts(
                                'showOverStorage',
                                result.overstorage,
                                'Your account is over the ' +
                                    maxStorage +
                                    'GB storage limit and no data is being displayed.'
                            );

                            AccountSettingsService.overLimitOrOverStorage = result.overlimit || result.overstorage;
                        });
                    }
                }
                updateLimitNotifications();

                function updateSupportMSANotice() {
                    if (
                        authorizationService.hasRole([
                            authorizationService.roles.supportBasic,
                            authorizationService.roles.superAdmin
                        ])
                    ) {
                        updateNotificationAlerts('msaAcceptanceRequired', AccountSettingsService.msaAcceptanceRequired);
                    }
                }
                updateSupportMSANotice();

                const screenshotRedactionSettingsStoreUnsubscribe = screenshotRedactionSettingsStore.subscribe(
                    (store) => {
                        screenshotsRedactionService.getPreference().success(function (data) {
                            var showMessages = data.redactionEnabled && AccountSettingsService.redactionFeatureFlag;
                            updateNotificationAlerts(
                                'dlpNearingLimit',
                                showMessages && store.isNearingLimit && !store.isOverLimit
                            );
                            updateNotificationAlerts('dlpOverLimit', showMessages && store.isOverLimit);
                        });
                    }
                );

                $rootScope.$on('$destroy', function () {
                    screenshotRedactionSettingsStoreUnsubscribe();
                });

                $rootScope.$on('DeleteInProgress', function (e, inProgress) {
                    updateNotificationAlerts('deleteInProgress', inProgress);
                });

                $rootScope.$on('PreAggregationInProgress', function (e, preAggregationInProgress) {
                    updateNotificationAlerts('preAggregationInProgress', preAggregationInProgress);
                });

                $rootScope.$on('AccountSettingsInitialized', function () {
                    updateLimitNotifications();
                    updateSupportMSANotice();
                });

                $scope.$watch(
                    function () {
                        return $state.params.chromebookInstallError;
                    },
                    function () {
                        updateNotificationAlerts(
                            'chromebookInstallError',
                            !!$state.params.chromebookInstallError,
                            $state.params.chromebookInstallError
                        );
                    }
                );

                // TODO: Update to an event listener
                $scope.$watch(
                    function () {
                        return AccountSettingsService.hasFailedPayment;
                    },
                    function () {
                        $scope.updateBillingInfoUrl = AccountSettingsService.updateBillingInfoUrl;
                        updateNotificationAlerts(
                            'showFailedPayment',
                            AccountSettingsService.hasFailedPayment && !AccountSettingsService.isSubscriptionCancelled
                        );
                    }
                );

                // Legacy Notification Route.  Use notification service for new items.
                $rootScope.$on('showNotification', function (event, data) {
                    notificationService.showNotification(data.message, data.color, data.timeout, data.skipEscape);
                });
            }
        ]
    };
}
