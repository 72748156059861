import React, { useEffect, useState } from 'react';
import { ColDef } from 'ag-grid-community';
import {
  IWorkingHoursReportUserDto,
  WorkingHoursTabProps
} from '../models/WorkingHoursTypes';
import { useScheduledWorkingHoursReportState } from '../hooks/useScheduledWorkingHoursReportState';
import { getScheduledColumns } from '../utils/workingHoursReport.utils';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { generateParameters } from '../../../common/components/ReportFilters/utils/generateParameters';
import AtGrid from '../../../common/components/AtGrid/components/AtGrid';
import { NoReportData } from '../../common/components/NoReportData';

export const ScheduledWorkReport = (props: WorkingHoursTabProps) => {
  const { view } = props;

  const [selectedColumnFields, setSelectedColumnFields] =
    useState<ColDef<IWorkingHoursReportUserDto>[]>();

  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

  //TODO: 'view' needs to be passed to the api too
  const reportFiltersStr = generateParameters(reportFilters, {});

  const { data, isLoading, init } = useScheduledWorkingHoursReportState();

  useEffect(() => {
    const columns = getScheduledColumns({ view });
    if (columns) {
      // FUTURE: update 'hidden' statuses based on persisted values
      setSelectedColumnFields(columns);
    }
  }, [view]);

  useEffect(() => {
    init(reportFiltersStr);
  }, [init, reportFiltersStr]);

  return (
    selectedColumnFields && (
      <AtGrid
        data={data}
        selectedColumnFields={selectedColumnFields}
        isLoading={isLoading}
        gridOptionOverrides={{ noRowsOverlayComponent: NoReportData }}
      />
    )
  );
};
