import { LoadApplication } from '../../../_reactivtrak/src/common/services/Application/AppLoad';

angular.module('app').service('AccountSettingsService', AccountSettingsService);

AccountSettingsService.$inject = ['$rootScope', 'envConfig', 'atHelperFunctions'];

function AccountSettingsService($rootScope, envConfig, atHelperFunctions) {
    var service = this;

    function initialize(model) {
        for (var prop in model) {
            service[prop] = model[prop];
        }

        atHelperFunctions.setDateTimeFormats(service.dateFormat, service.timeFormat, service.currentIanaTimeZone);
        $rootScope.$broadcast('AccountSettingsInitialized');
    }

    this.resetAccountSettings = function () {
        service.username = '';
        service.totalLicenses = 3;
        service.user = {};
        service.subscriptions = {};
        service.account = {};
        service.bundleFlags = {};
    };

    this.loadSettings = function (settings) {
        initialize(settings);
    };
}

angular.module('app').factory('accountApiHelper', accountApiHelper);
accountApiHelper.$inject = [
    '$state',
    '$q',
    'AccountSettingsService',
    'atHelperFunctions',
    'authorizationService',
    'loginService',
    'accessApiService'
];

function accountApiHelper(
    $state,
    $q,
    AccountSettingsService,
    atHelperFunctions,
    authorizationService,
    loginService,
    accessApiService
) {
    var loadSettings = function () {
        var deferred = $q.defer();

        function errorHandler(error) {
            // Ignore 401 status since it is handled in responseErrorService.js
            if (error.status !== 401) {
                var errorCode = error && error.data && error.data.code;
                var params = {
                    apiFailure: true
                };

                if (errorCode && errorCode.indexOf('IN_MAINTENANCE_MODE') !== -1) {
                    params = {
                        maintenanceModeReason: error.data.message
                    };
                } else if (errorCode && errorCode.indexOf('MSA_ACCEPTANCE_NEEDED') !== -1) {
                    params = {
                        logoutReason: error.data.message
                    };
                }

                // Clean global environment
                loginService.clearScope();
                deferred.reject(error);

                if ($state.current.name !== 'login') {
                    loginService.logout(params);
                } else {
                    loginService.clearLastLogin();
                }
            }
        }

        function completeLoad(model) {
            AccountSettingsService.loadSettings(model);
            deferred.resolve(AccountSettingsService);
        }

        LoadApplication()
            .then(function (appSettings) {
                atHelperFunctions
                    .removeUtmFromUrl()
                    .then(function () {
                        if (
                            authorizationService.hasRole(authorizationService.roles.user) &&
                            appSettings.accountSettings.msaAcceptanceRequired
                        ) {
                            errorHandler({
                                status: -1,
                                data: {
                                    code: 'MSA_ACCEPTANCE_NEEDED',
                                    message:
                                        'Account Locked.  An admin of your ActivTrak account needs to finish the upgrade process.'
                                }
                            });
                        }
                        accessApiService
                            .setFilterDateLimits()
                            .then(function () {
                                completeLoad(appSettings.accountSettings);
                            })
                            .catch(errorHandler);
                    })
                    .catch(errorHandler);
            })
            .catch(errorHandler);
        return deferred.promise;
    };

    return {
        loadSettings: loadSettings
    };
}
