import { createStore } from 'zustand';
import { IApiDomainStore } from '../models/IApiDomainStore';
import { activTrakConfig } from '../helpers/activTrakConfig';
import { buildHttps, buildWss, fetchUrls } from '../utils/apiDomainStore.utils';

const API_DOMAIN_STORE_DEFAULT: IApiDomainStore = {
  defaultDomain: activTrakConfig.defaultUrl,
  frontendApi: buildHttps(activTrakConfig.apiUrl ?? activTrakConfig.defaultUrl),
  authorizationService: buildHttps(
    activTrakConfig.authorizationServiceUrl ?? activTrakConfig.defaultUrl
  ),
  ssoAuthorization: buildHttps(
    activTrakConfig.ssoAuthorizationUrl ?? activTrakConfig.defaultUrl
  ),
  reportingService: buildHttps(
    activTrakConfig.reportingServiceUrl ?? activTrakConfig.defaultUrl
  ),
  accountsApi: buildHttps(
    activTrakConfig.accountsApiUrl ?? activTrakConfig.defaultUrl
  ),
  adminApi: buildHttps(
    activTrakConfig.adminApiUrl ?? activTrakConfig.defaultUrl
  ),
  identityService: buildHttps(
    activTrakConfig.identityServiceUrl ?? activTrakConfig.defaultUrl
  ),
  accountApiKeyPage: buildHttps(activTrakConfig.defaultUrl),
  websocket: buildWss(activTrakConfig.websocketUrl),
  websocketHttps: buildHttps(activTrakConfig.websocketUrl)
};

export const apiDomainStore = createStore<IApiDomainStore>(
  () => API_DOMAIN_STORE_DEFAULT
);

/**
 * Fetches the account's region based API Domains.
 */
export const fetchApiDomains = async (): Promise<IApiDomainStore> => {
  if (!activTrakConfig.disableRegionRedirect) {
    try {
      const apiDomains = await fetchUrls();
      apiDomainStore.setState((prevState) => ({ ...prevState, ...apiDomains }));
    } catch (error) {
      console.error('ActivTrak Error: Error fetching account urls', error);
      throw error;
    }
  }

  return apiDomainStore.getState();
};

/**
 * Returns the set account's region based API Domains.
 */
export const getApiDomainStore = (): IApiDomainStore =>
  apiDomainStore.getState();

/**
 * Resets the account's region based API Domains to the default state.
 */
export const resetApiDomainStore = (): void =>
  apiDomainStore.setState(API_DOMAIN_STORE_DEFAULT);
