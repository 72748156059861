import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { localStorageItems } from '../../common/constants';
import { UsersProvider, UseUsersContext } from '../services';
import { useNotifications } from '../../common/services/Notifications';
import { IAgentsListFilter, IFetchAllUsers, IUser } from '../models';
import { IAccountSettings } from '../../common/models';
import { useAuthorization } from '../../common/services/Authorization';
import { useUIRouterQueryString } from '../../common/hooks/useUIRouterQueryString';
import { ComputerIcon } from '../../common/assets/Icons';
import CustomGrid from '../../common/components/Grid/CustomGrid';
import { Role, RoleAccessKeys, SortDirection } from '../../common/enums';
import { TimeAgo } from '../../common/components/TimeAgo';
import authHelper from '../../common/helpers/authorization';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { DownloadProvider } from '../../common/services';
import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';
import {
  bulkFilterMenuItems,
  filterUserAgentsByDateRange,
  USERS_INITIAL_VIEW_STATE
} from '../utils';
import { getPrivacySettings } from '../../common/hooks/privacySettingsStore';
import {
  filterAndRepeatDataBySpecificParamsAndQuery,
  filterDataBySpecificParamsAndQuery,
  getParsedLocalStorageItem,
  sorter
} from '../../common/helpers';
import { AutocompleteSearchBar } from '../../common/components/AutocompleteSearchBar';
import { TagBar } from '../../common/components/TagBar';
import { AdvancedFilter } from '../../common/components/AdvancedFilter';
import { DownloadAgentButton } from '../../common/components/DownloadAgentButton';
import { DropdownOptionsButton } from '../../common/components/DropdownOptionsButton';
import { ListFilter } from '../../common/components/Filter/ListFilter';

import { DeleteUsersConfirmationModal } from '../components/DeleteUsersConfirmationModal';
import { DoNotTrackUsersConfirmationModal } from '../components/DoNotTrackUsersConfirmationModal';
import { MergeUsersConfirmationModal } from '../components/MergeUsersConfirmationModal';
import { ScheduledUserMergeModal } from '../components/ScheduledUserMergeModal';
import { ScheduledDeletesModal } from '../components/ScheduledDeletesModal';
import { UserAdvancedSearchForm } from '../components/UserAdvancedSearchForm';
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteIcon from '@mui/icons-material/Delete';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import ClearIcon from '@mui/icons-material/Clear';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  AdvancedFilterButtonContainer,
  GridRowActionButtonContainer,
  HeaderBarActionButtons,
  HeaderBarContainer,
  HeaderBarLeftActionButtons,
  HeaderBarTopComponentsButtons,
  HeaderBarTopComponentsContainer,
  SearchBarContainer,
  TotalsGrouper,
  UserHeaderBarRightActionButtons,
  UsersGridContainer,
  usersViewStyles as classes
} from '../styles';
import { BundleFlag } from '../../common/enums/BundleFlag';
import { ScheduledDeleteModes } from '../enums';
import moment from 'moment';
import { userTextFields } from '../enums/AdvancedSearch';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '../../common/components/Link';
import Tooltip from '@mui/material/Tooltip';
import { formatDateTime } from '../../common/utils/datetime/datetimeFormatters';
import {
  getTimeLapsedSinceNowString,
  getTimeLapsedSinceNowStringByTimezone
} from '../../common/utils/datetime/datetimeCalculations';

export function UsersView() {
  const history = useUIRouterHistory();

  const { usersService } = UseUsersContext();
  const notificationService = useNotifications();
  const scheduledMergeModalRef = useRef(null);
  const scheduledDeleteModalRef = useRef(null);
  const authorization = useAuthorization();
  const urlQuery = useUIRouterQueryString();
  const { screenshotsAllowed = false } = getPrivacySettings();
  const userIsSuperAdminOrAdmin = authorization.hasAnyRole([
    Role.SuperAdmin,
    Role.Admin
  ]);
  const userIsAdminOrAdvancedSupport = authorization.hasAnyRole([
    Role.SuperAdmin,
    Role.Admin,
    Role.SupportAdvanced
  ]);

  const hasAlarmsScreenshotsReportsRoleAccess = authHelper.hasRoleAccess(
    RoleAccessKeys.AlarmsScreenshotsReports
  );

  const isUserIdentityEnabled = authorization.hasFeature(
    BundleFlag.UserIdentityConfiguration
  );

  const softDeletesEnabled = authorization.hasFeature(BundleFlag.SoftDeletes);

  const userGridColumns = [
    {
      field: 'id',
      fieldName: 'id',
      headerName: 'ID',
      type: 'string',
      isSortable: true,
      width: '5%'
    },
    {
      field: 'logonDomain',
      fieldName: 'logonDomain',
      headerName: `Logon Domain`,
      type: 'string',
      isSortable: true,
      width: '20%',
      template: (dataItem) => {
        return (
          <>
            <ComputerIcon className="v-align-middle"></ComputerIcon>
            <span style={classes.domainName}>{dataItem.logonDomain}</span>
          </>
        );
      }
    },
    {
      field: 'user',
      headerName: 'User (Alias)',
      isSortable: true,
      type: 'string',
      width:
        screenshotsAllowed && hasAlarmsScreenshotsReportsRoleAccess
          ? '21%'
          : '33%',
      template: (dataItem) => {
        const { user, alias } = dataItem;
        let labelText = ``;
        if (user) {
          labelText += `${user}`;
        }
        if (alias) {
          if (labelText.length) {
            labelText += ` `;
          }
          labelText += `(${alias})`;
        }
        return labelText;
      }
    },
    // {
    //   field: 'alias',
    //   headerName: 'Alias',
    //   hidden: true,
    //   isSortable: true,
    //   type: 'string',
    //   width: '5%'
    // },
    {
      field: 'firstLog',
      headerName: 'First Log Record',
      type: 'date',
      isSortable: true,
      width: '15%',
      template: (dataItem) => {
        let firstLog = dataItem.firstLog;
        if (softDeletesEnabled) {
          //admin api returns lastlog as a string in UTC
          firstLog = moment.utc(dataItem.firstLog).toDate();
        }
        return <>{formatDateTime(firstLog)}</>;
      }
    },
    {
      field: 'lastLog',
      headerName: 'Last Log Record',
      isSortable: true,
      type: 'string',
      width: '12%',
      template: (dataItem) => {
        const { lastLog } = dataItem;
        const timeAgo = softDeletesEnabled
          ? getTimeLapsedSinceNowString(lastLog)
          : getTimeLapsedSinceNowStringByTimezone(lastLog);

        return (
          <TimeAgo
            lastLog={lastLog}
            learnMoreLink="https://support.activtrak.com/hc/en-us/articles/4407841903515-Managing-User-Agent"
            timeAgo={timeAgo}
          />
        );
      }
    },
    {
      field: 'lastss',
      headerName: 'Last Screenshot',
      isSortable: true,
      hidden:
        (!screenshotsAllowed || !hasAlarmsScreenshotsReportsRoleAccess) ?? true,
      type: 'string',
      width: '12%',
      template: (dataItem) => {
        const { lastss } = dataItem;
        const timeAgo = softDeletesEnabled
          ? getTimeLapsedSinceNowString(lastss)
          : getTimeLapsedSinceNowStringByTimezone(lastss);

        return (
          <Tooltip title={formatDateTime(dataItem.lastss)} placement="bottom">
            <div>{timeAgo}</div>
          </Tooltip>
        );
      }
    },
    {
      isSortable: false,
      width: '5%',
      type: 'string',
      template: (dataItem) => {
        const { id, selected } = dataItem;
        return (
          <GridRowActionButtonContainer>
            <DropdownOptionsButton
              id="e3489b34-3637-4b0c-a505-c3cde49373a1"
              icon={<MoreVertIcon />}
              dropDownOptions={[{ label: 'Delete' }, { label: 'Do Not Track' }]}
              onChangeOption={(e, option) => {
                const { label } = option;
                if (label === 'Delete') {
                  deleteUserCallbackRef.current = async () => {
                    await deleteUsersByIds([id]);
                  };
                  setOpenDeleteUsersConfirmationModal(true);
                }
                if (label === 'Do Not Track') {
                  const dntUsers = new Array(dataItem);
                  setDoNotTrackUsers(dntUsers);
                  setOpenDoNotTrackConfirmationModal(true);
                }
              }}
              hideSelectedOption
              disableSelectedItem
              disabled={selected || !userIsSuperAdminOrAdmin}
              sx={{ width: '24px', minWidth: 'auto' }}
            />
          </GridRowActionButtonContainer>
        );
      }
    }
  ];

  const [users, setUsers] = useState<IUser[]>(
    USERS_INITIAL_VIEW_STATE.reportingUsers
  );
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [doNotTrackUsers, setDoNotTrackUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState(
    USERS_INITIAL_VIEW_STATE.searchTerm
  );
  const [dateFilter, setDateFilter] = useState<IAgentsListFilter>();
  const [viewIsLoading, setViewIsLoading] = useState(
    USERS_INITIAL_VIEW_STATE.viewIsLoading
  );
  const [gridOrderBy, setGridOrderBy] = useState();
  const [gridOrderDirection, setGridOrderDirection] = useState();
  const [
    openDeleteUsersConfirmationModal,
    setOpenDeleteUsersConfirmationModal
  ] = useState<boolean>(false);
  const [openDoNotTrackConfirmationModal, setOpenDoNotTrackConfirmationModal] =
    useState<boolean>(false);
  const [openMergeUsersConfirmationModal, setOpenMergeUsersConfirmationModal] =
    useState<boolean>(false);
  const [openScheduledUserMergesModal, setOpenScheduledUserMergeModal] =
    useState<boolean>(false);
  const [openScheduledDeletesModal, setOpenScheduledDeletesModal] =
    useState<boolean>(false);

  const [tabInputData, setTabInputData] = useState([]);
  const [showAdvancedFilterModal, setShowAdvancedFilterModal] = useState(false);

  useEffect(() => {
    const limit = urlQuery?.get('limit');

    if (limit) {
      const newLimit: IAgentsListFilter = bulkFilterMenuItems.filter(
        (option) => option.filterValue == limit
      )[0];
      setDateFilter((prevState) => ({ ...prevState, ...newLimit }));
    }
  }, [urlQuery]);

  const refreshUsers = useCallback(() => {
    setViewIsLoading(true);
    usersService
      .fetchAllUsers()
      .then((response: IFetchAllUsers) => {
        const { users } = response;
        setUsers(users);
      })
      .catch(() => {
        //what should happen here?
      })
      .finally(() => {
        setViewIsLoading(false);
      });
  }, [usersService]);

  useEffect(() => {
    refreshUsers();
  }, [refreshUsers]);

  const getTime = (date?: Date) => {
    return date != null ? date.getTime() : 0;
  };

  const selectedUsersSorted = useMemo(() => {
    const sortedUsers = [...selectedUsers].sort(function (a, b) {
      return getTime(new Date(b.lastLog)) - getTime(new Date(a.lastLog));
    });
    return sortedUsers;
  }, [selectedUsers]);

  const pendingUserMergesExist = useMemo(() => {
    let userMergeExists = false;
    users.forEach((user) => {
      if (user.mergePending) {
        userMergeExists = true;
      }
    });
    return userMergeExists;
  }, [users]);

  const pendingUserDeletionsExist = useMemo(() => {
    let userDeletionExists = false;
    users.forEach((user) => {
      if (user.scheduledActionName === 'delete') {
        userDeletionExists = true;
      }
    });
    return userDeletionExists;
  }, [users]);

  const accountSettings: IAccountSettings = getParsedLocalStorageItem(
    localStorageItems.accountSettingsStorage
  );

  const deleteUserCallbackRef = useRef(() => {});

  const filteredUsers = useMemo(() => {
    let newFilteredUsers;
    if (tabInputData.length) {
      newFilteredUsers = users;
      tabInputData.forEach((tabInput) => {
        const { field, fieldValue } = tabInput;
        newFilteredUsers = filterDataBySpecificParamsAndQuery(
          newFilteredUsers,
          [field],
          fieldValue
        );
      });
    } else if (searchTerm && users.length) {
      newFilteredUsers = filterDataBySpecificParamsAndQuery(
        users,
        USERS_INITIAL_VIEW_STATE.searchBarFilterParams,
        searchTerm
      );
    } else {
      newFilteredUsers = users;
    }

    if (dateFilter?.filterValue) {
      newFilteredUsers = filterUserAgentsByDateRange(
        newFilteredUsers,
        parseInt(dateFilter?.filterValue),
        accountSettings?.currentIanaTimeZone
      );
    }

    const sortedData = sorter(
      newFilteredUsers,
      gridOrderDirection,
      gridOrderBy
    );
    return sortedData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    users,
    searchTerm,
    dateFilter?.filterValue,
    tabInputData,
    gridOrderDirection,
    gridOrderBy
  ]);

  const getSearchLabelPlaceholder = () => {
    return 'Search by Logon Domain, User, or User Alias';
  };

  const getFilteredUsersForAutocomplete = () => {
    const filteredData = filterAndRepeatDataBySpecificParamsAndQuery(
      users,
      userGridColumns,
      USERS_INITIAL_VIEW_STATE.searchBarFilterParams,
      searchTerm
    );

    const newDataMap = {};
    const uniqueGroups = {};
    for (let i = 0; filteredData.length > i; i++) {
      const { id, type, label } = filteredData[i];
      if (type !== USERS_INITIAL_VIEW_STATE.searchBarFilterParams[0]) {
        newDataMap[id + type] = filteredData[i];
      } else {
        if (!uniqueGroups[label]) {
          newDataMap[id + type] = filteredData[i];
          uniqueGroups[label] = true;
        }
      }
    }

    return Object.keys(newDataMap).map(function (key) {
      return newDataMap[key];
    });
  };

  const handleChangeAutocompleteSearchBar = (value) => {
    setSearchTerm(value);
  };

  const handleClickRowGrid = useCallback(
    (rowItem) => {
      history.push('app.settings.useragents_id', {
        id: rowItem.id,
        user: { ...rowItem }
      });
    },
    [history]
  );

  const resetSelectedUsers = () => {
    setSelectedUsers([]);
    setUsers(
      users.map((item) => {
        return { ...item, selected: false };
      })
    );
  };

  const handleUserGridChange = () => {
    //selectedData comes from the viewable list.  use our own filtered user list instead
    const gridUsersSelected = users.filter((item) => item.selected);
    setSelectedUsers(gridUsersSelected);
  };

  const onSubmitAdvancedFilter = useCallback(
    (formData) => {
      const tabBarData = [];
      Object.keys(formData).forEach((key) => {
        if (formData[key]) {
          const userField = userTextFields.find(
            (gridColumn) => gridColumn.name == key
          );
          tabBarData.push({
            field: key,
            fieldName: userField?.label,
            fieldValue: formData[key]
          });
        }
      });
      setTabInputData(tabBarData);
    },
    [setTabInputData]
  );

  const userGridRowDisableCondition = useCallback((rowItem) => {
    if (rowItem.scheduledActionId === undefined) {
      return false;
    }
    if (rowItem.mergePending) {
      return true;
    }
    return !(rowItem.scheduledActionId === 0);
  }, []);

  const handleDeleteUsersClick = async () => {
    deleteUserCallbackRef.current = async () => {
      const userIds = selectedUsers.map((user) => {
        const { id } = user;
        return id;
      });
      try {
        await deleteUsersByIds(userIds);
        resetSelectedUsers();
      } catch (response) {
        //what should happen here?
      }
    };
    setOpenDeleteUsersConfirmationModal(true);
  };

  const filterOrDisableUsers = (userIds: number[], actionName: string) => {
    if (!softDeletesEnabled && !accountSettings.delayedDelete) {
      const newUsers = users.filter((user) => !userIds.includes(user.id));
      setUsers(newUsers);
    } else {
      disableUsers(userIds, actionName);
    }
  };

  const disableUsers = (userIds: number[], actionName: string) => {
    const copiedUsers = [...users];
    copiedUsers.forEach((user) => {
      if (userIds.includes(user.id)) {
        if (actionName === 'merge') {
          user.mergePending = true;
        } else {
          //delayed delete just disables the row
          user.scheduledActionId = 1;
          user.scheduledActionName = actionName;
        }
      }
    });
    setUsers(copiedUsers);
  };

  const deleteUsersByIds = async (userIds: number[]) => {
    try {
      await usersService.deleteUsers(userIds);
      notificationService.success(`Success! ${userIds.length} users deleted.`);
      filterOrDisableUsers(userIds, 'delete');
      refreshUsers();
    } catch (response) {
      notificationService.error(response.data.error);
    }
  };

  const mergeUsersCallback = async () => {
    try {
      await mergeSelectedUsers(selectedUsersSorted);
      resetSelectedUsers();
    } catch (response) {
      //what should happen here?
    }
  };

  const cancelMergeCallback = async () => {
    refreshUsers();
  };

  const cancelDeletesCallback = async () => {
    refreshUsers();
  };

  const handleScheduledMergesClick = async () => {
    setOpenScheduledUserMergeModal(true);
    scheduledMergeModalRef.current.loadScheduledMerges();
  };

  const handleScheduledDeletesClick = async () => {
    setOpenScheduledDeletesModal(true);
    scheduledDeleteModalRef.current.loadScheduledActions();
  };

  const handleMergeUsersClick = async () => {
    setOpenMergeUsersConfirmationModal(true);
  };

  const doNotTrackModalCallback = async () => {
    try {
      await doNotTrackUsersAdd(doNotTrackUsers);
      resetSelectedUsers();
      setDoNotTrackUsers([]);
    } catch (response) {
      //what should happen here?
    }
  };

  const handleDoNotTrackClick = async () => {
    setDoNotTrackUsers(selectedUsers);
    setOpenDoNotTrackConfirmationModal(true);
  };

  const doNotTrackUsersAdd = async (dntUsers: IUser[]) => {
    try {
      const userIds = dntUsers.map((user) => {
        const { id } = user;
        return id;
      });
      await usersService.doNotTrackUsers(dntUsers);
      notificationService.success(
        `Success! ${dntUsers.length} are added to do not track list.`
      );
      filterOrDisableUsers(userIds, 'delete');
      refreshUsers();
    } catch (response) {
      notificationService.error(response.data.error);
    }
  };

  const mergeSelectedUsers = async (usersToMerge: IUser[]) => {
    try {
      const userIds = usersToMerge.map((user) => {
        const { id } = user;
        return id;
      });
      await usersService.mergeUsers(usersToMerge);
      notificationService.success(`Success! ${userIds.length} users merged.`);
      disableUsers(userIds, 'merge');
    } catch (response) {
      notificationService.error(response.data.error);
    }
  };

  const handleDownload = () => {
    handleOnProgBarStart();
    usersService.downloadCSV().then((response) => {
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(
        new Blob([response.data], { type: 'text/csv;charset=utf-8' })
      );
      reader.onloadend = () => {
        const result = reader.result as string;
        const downloadLink = document.createElement('a');
        downloadLink.href = decodeURIComponent(result);
        downloadLink.download = 'userAgents.csv';

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        handleOnProgBarEnd();
      };
    });
  };

  const handleOnProgBarStart = () => {
    setShowProgbarDwnld(true);
  };

  const handleOnProgBarEnd = () => {
    setShowProgbarDwnld(false);
    setEndProgbarDwnld(true);
  };

  const [showProgbarDwnld, setShowProgbarDwnld] = useState<boolean>(false);
  const [endProgbarDwnld, setEndProgbarDwnld] = useState<boolean>(false);

  const resetProgBarEnd = () => {
    setShowProgbarDwnld(false);
    setEndProgbarDwnld(false);
  };

  const onSortOrder = useCallback((newOrderDirection, newOrderBy) => {
    setGridOrderBy(newOrderBy);
    setGridOrderDirection(newOrderDirection);
  }, []);

  return (
    <Box>
      {showProgbarDwnld && (
        <div style={classes.dispProgress}>
          <div>Generating Export</div>
          <div style={classes.uploadFileProgressContainer}>
            <div style={classes.uploadFileProgressBar} />
            <LinearProgress />
          </div>
        </div>
      )}
      {endProgbarDwnld && (
        <div style={classes.dispResultBar}>
          <Alert
            severity="info"
            onClose={() => {
              resetProgBarEnd();
            }}
          >
            <p style={{ color: 'black', margin: '0px 0px 0px 0px' }}>
              Download Complete{' '}
            </p>
          </Alert>
        </div>
      )}
      <HeaderBarContainer>
        <Box>
          {isUserIdentityEnabled && (
            <Alert
              severity="info"
              icon={false}
              sx={{
                justifyContent: 'center',
                '> div': { textAlign: 'center' },
                mb: 3
              }}
            >
              If you want to start or stop tracking agents that are associated
              with a specific user, you can do that from the{' '}
              <Link to="app.settings.identity">Users page</Link>
            </Alert>
          )}
        </Box>
        <HeaderBarTopComponentsContainer>
          <HeaderBarTopComponentsButtons></HeaderBarTopComponentsButtons>
          <SearchBarContainer>
            {tabInputData.length ? (
              <TagBar tabBarData={tabInputData} />
            ) : (
              <AutocompleteSearchBar
                autocompleteOptions={getFilteredUsersForAutocomplete()}
                searchLabelPlaceholder={getSearchLabelPlaceholder()}
                onChange={handleChangeAutocompleteSearchBar}
              />
            )}
            {searchTerm ? null : (
              <AdvancedFilterButtonContainer>
                {tabInputData.length ? (
                  <IconButton
                    onClick={() => setTabInputData([])}
                    color="secondary"
                  >
                    <ClearIcon />
                  </IconButton>
                ) : null}
                <AdvancedFilter
                  showModal={showAdvancedFilterModal}
                  setShowModal={setShowAdvancedFilterModal}
                  form={
                    <UserAdvancedSearchForm
                      modalData={tabInputData}
                      onSubmit={(formData) => {
                        setShowAdvancedFilterModal(false);
                        onSubmitAdvancedFilter(formData);
                      }}
                    />
                  }
                />
              </AdvancedFilterButtonContainer>
            )}
          </SearchBarContainer>
        </HeaderBarTopComponentsContainer>
        <HeaderBarActionButtons>
          <HeaderBarLeftActionButtons>
            <Button
              startIcon={<DeleteIcon />}
              onClick={handleDeleteUsersClick}
              disabled={
                !userIsSuperAdminOrAdmin ||
                selectedUsers == null ||
                selectedUsers?.length == 0
              }
              color="secondary"
            >
              Delete
            </Button>
            <Button
              startIcon={<VisibilityOffIcon />}
              onClick={handleDoNotTrackClick}
              disabled={
                !userIsSuperAdminOrAdmin ||
                selectedUsers == null ||
                selectedUsers?.length == 0
              }
              color="secondary"
            >
              Do Not Track
            </Button>
            <Button
              startIcon={<MergeTypeIcon />}
              onClick={handleMergeUsersClick}
              disabled={
                !userIsSuperAdminOrAdmin ||
                selectedUsers == null ||
                selectedUsers?.length < 2
              }
              color="secondary"
            >
              Merge Users
            </Button>
            {pendingUserMergesExist ? (
              <Button
                startIcon={<MergeTypeIcon />}
                onClick={handleScheduledMergesClick}
                color="secondary"
              >
                Scheduled Merges
              </Button>
            ) : null}
            {pendingUserDeletionsExist && userIsAdminOrAdvancedSupport ? (
              <Button
                startIcon={<DeleteIcon />}
                onClick={handleScheduledDeletesClick}
                color="secondary"
              >
                Scheduled Deletes
              </Button>
            ) : null}
          </HeaderBarLeftActionButtons>
          <UserHeaderBarRightActionButtons>
            <TotalsGrouper>
              <span>{`TOTAL USERS: `}</span>
              {filteredUsers.length}
            </TotalsGrouper>
            <ListFilter
              listItems={bulkFilterMenuItems}
              onClick={setDateFilter}
              defaultSelection={dateFilter?.label}
            />
            <Button
              startIcon={<GetAppIcon />}
              onClick={handleDownload}
              color="secondary"
            >
              Export
            </Button>
            <DownloadAgentButton />
          </UserHeaderBarRightActionButtons>
        </HeaderBarActionButtons>
      </HeaderBarContainer>
      <UsersGridContainer sx={{ mt: 1 }}>
        {(viewIsLoading || filteredUsers.length > 0) && (
          <CustomGrid
            isLoading={viewIsLoading}
            isVirtualized={true}
            data={filteredUsers}
            columns={userGridColumns}
            onClickRow={handleClickRowGrid}
            uniqueRowProp="id"
            initialSortField="lastLog"
            initialSortDirection={SortDirection.Ascending}
            hasBulkSelection
            rowDisableCondition={userGridRowDisableCondition}
            featureDisabled={!userIsSuperAdminOrAdmin}
            onSelectAllClick={handleUserGridChange}
            onSortOrder={onSortOrder}
          />
        )}
        {!viewIsLoading && filteredUsers.length == 0 && (
          <span>{'No user agents found.'}</span>
        )}
      </UsersGridContainer>
      <DeleteUsersConfirmationModal
        open={openDeleteUsersConfirmationModal}
        onClose={() => setOpenDeleteUsersConfirmationModal(false)}
        onDelete={deleteUserCallbackRef.current}
      />
      <DoNotTrackUsersConfirmationModal
        open={openDoNotTrackConfirmationModal}
        onClose={() => setOpenDoNotTrackConfirmationModal(false)}
        onDoNotTrack={doNotTrackModalCallback}
        selectedUsers={doNotTrackUsers}
      />
      <MergeUsersConfirmationModal
        open={openMergeUsersConfirmationModal}
        onClose={() => setOpenMergeUsersConfirmationModal(false)}
        onMergeUsers={mergeUsersCallback}
        selectedUsers={selectedUsersSorted}
        mostRecentlyUpdatedUser={
          selectedUsersSorted.length > 0 ? selectedUsersSorted[0] : {}
        }
      />
      <ScheduledUserMergeModal
        open={openScheduledUserMergesModal}
        onClose={() => setOpenScheduledUserMergeModal(false)}
        onCancelMerge={cancelMergeCallback}
        ref={scheduledMergeModalRef}
      />
      <ScheduledDeletesModal
        mode={ScheduledDeleteModes.Users}
        open={openScheduledDeletesModal}
        onClose={() => setOpenScheduledDeletesModal(false)}
        onCancel={cancelDeletesCallback}
        ref={scheduledDeleteModalRef}
      />
    </Box>
  );
}

export const UsersViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <DownloadProvider>
      <UsersProvider>
        <UsersView />
      </UsersProvider>
    </DownloadProvider>
  </ReactivTrakComponentWrapper>
);
