import appVersion from '../../../../version.json';
import { FeatureFlag } from '../../../../_reactivtrak/src/common/enums/FeatureFlag';
import { getScreenshotSettingsStore } from '../../../../_reactivtrak/src/common/stores/screenshotSettingsStore';

angular.module('app').controller('SupportModalCtrl', SupportModalCtrl);

SupportModalCtrl.$inject = [
    '$window',
    '$scope',
    '$uibModalInstance',
    'constantsService',
    'AccountSettingsService',
    'authorizationService',
    'templateServiceFunctions',
    'dataCenterService',
    'envConfig'
];

function SupportModalCtrl(
    $window,
    $scope,
    $uibModalInstance,
    constant,
    AccountSettingsService,
    authorizationService,
    templateServiceFunctions,
    dataCenterService,
    envConfig
) {
    $scope.sections;
    $scope.supportRole = authorizationService.hasRole(authorizationService.roles.supportBasic);
    $scope.activTrakId = AccountSettingsService.username;
    $scope.accountNumber = AccountSettingsService.account;
    var version = (appVersion && appVersion.version) || 'x.x.x';

    // eslint-disable-next-line no-undef
    var commitHash = __COMMIT_HASH__;
    $window.commitHash = commitHash;

    // Convert unix time to moment and generate creation length text
    if (AccountSettingsService.unixCreationTime && AccountSettingsService.unixCreationTime > 0) {
        var creationDateObject = moment.unix(AccountSettingsService.unixCreationTime);
        var creationLengthString = ' (' + templateServiceFunctions.getCreationLengthString(creationDateObject) + ')';
        $scope.creationDate = creationDateObject.format('Y-MM-DD hh:mm:ss A Z') + creationLengthString;
    } else {
        $scope.creationDate = 'Unknown';
    }

    $scope.getColor = function (value) {
        return typeof value === 'boolean'
            ? value
                ? constant.get('color', 'COLOR-SUCCESS')
                : constant.get('color', 'COLOR-DANGER')
            : constant.get('color', 'COLOR-COMPLETE');
    };

    $scope.getFieldValue = function (field) {
        var value = typeof field.value === 'function' ? field.value() : field.value;

        if (field.subParam) {
            value = value[field.subParam];
        }

        return value;
    };

    function timeFormat(offset) {
        return Math.floor(offset / 60) + ':' + (new Array(2).join('0') + (Math.abs(offset) % 60)).slice(-2);
    }

    function getTimezone() {
        return (
            AccountSettingsService.currentTimeZone +
            ' (UTC' +
            (
                '-' + timeFormat(moment.tz.zone(AccountSettingsService.currentIanaTimeZone).utcOffset(new Date()))
            ).replace('--', '+') +
            ')'
        );
    }

    function loadSections() {
        if (!AccountSettingsService) {
            return;
        }

        var mappedBundleFlags = Object.entries(AccountSettingsService.bundleFlags)
            .map(function ([key, value]) {
                return { label: key, value: value };
            })
            .sort(function (a, b) {
                return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
            });

        const { screenshotFeatureFlag } = getScreenshotSettingsStore();

        $scope.sections = [
            {
                title: 'Account Flags',
                fields: [
                    {
                        label: 'Paid Account',
                        value: authorizationService.hasFeature('isPaidFunctionalityEnabled')
                    },
                    {
                        label: 'Failed Payment',
                        value: AccountSettingsService.hasFailedPayment
                    },
                    {
                        label: 'Plan Cancelled',
                        value: AccountSettingsService.isSubscriptionCancelled
                    },
                    {
                        label: 'Verified',
                        value: AccountSettingsService.isVerified
                    },
                    {
                        label: 'Auto Update',
                        value: AccountSettingsService.autoUpdate
                    },
                    {
                        label: 'Mac Auto Update',
                        value: AccountSettingsService.macAutoupdate
                    },
                    {
                        label: 'Data Collection',
                        value: AccountSettingsService.dataCollection
                    },
                    {
                        label: 'MSA Acceptance Required',
                        value: AccountSettingsService.msaAcceptanceRequired
                    },
                    {
                        label: 'Screen View',
                        value: screenshotFeatureFlag
                    },
                    {
                        label: 'State',
                        value: AccountSettingsService.state
                    },
                    {
                        label: 'Command Center',
                        value: $scope.hasCommandCenter
                    }
                ]
            },
            {
                title: 'Feature Flags',
                fields: [
                    {
                        label: 'Screenshot Redaction Enabled',
                        value: AccountSettingsService.redactionFeatureFlag
                    },
                    {
                        label: 'Screenshot Flagging Enabled',
                        value: AccountSettingsService.safeSearchFeatureFlag
                    },
                    {
                        label: 'ActivInsights Enabled',
                        value: authorizationService.hasFeature(FeatureFlag.InsightsEnabled)
                    },
                    {
                        label: 'ActivInsights Available',
                        value: authorizationService.hasFeature(FeatureFlag.InsightsAvailable)
                    },
                    {
                        label: 'Video Alarms Enabled',
                        value: authorizationService.hasFeature(FeatureFlag.VideoPlayback)
                    },
                    {
                        label: 'Video Alarms Limit',
                        value: function () {
                            return AccountSettingsService.videoAlarmsLimit === 0
                                ? 'Unlimited'
                                : AccountSettingsService.videoAlarmsLimit;
                        }
                    },
                    {
                        label: 'Video Alarms Active',
                        value: AccountSettingsService.videoAlarmsActive
                    },
                    {
                        label: 'Delay Delete',
                        value: AccountSettingsService.delayedDelete
                    },
                    {
                        label: 'User Merge',
                        value: AccountSettingsService.userMergeEnabled
                    }
                ]
            },
            {
                title: 'Data Flags',
                fields: [
                    {
                        label: 'Data Connect Enabled',
                        value: AccountSettingsService.isDataConsoleEnabled
                    },
                    {
                        label: 'Preaggregation Enabled',
                        value: AccountSettingsService.isPreaggregationEnabled
                    },
                    {
                        label: 'Time Zone',
                        value: getTimezone
                    }
                ]
            },
            {
                title: 'Region Info',
                fields: [
                    {
                        label: 'Region',
                        value: dataCenterService.region,
                        subParam: 'name'
                    },
                    {
                        label: 'Database',
                        value: AccountSettingsService.databaseName
                    },
                    {
                        label: 'API Type',
                        value: dataCenterService.regionType
                    },
                    {
                        label: 'API Url',
                        value: envConfig.apiUrl
                    }
                ]
            },
            {
                title: 'App Info',
                fields: [
                    {
                        label: 'Version',
                        value: version + '_' + commitHash
                    }
                ]
            },
            {
                title: 'Bundle Flags',
                fields: mappedBundleFlags
            }
        ];

        return;
    }
    loadSections();

    $scope.refresh = function () {
        loadSections();
    };

    $scope.dismiss = function () {
        $uibModalInstance.dismiss();
    };
}
