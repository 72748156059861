import angular from 'angular';
import { react2angular } from '../common/third-party/react2angular';
import { FlexibleHoursReportViewComponent } from '../reports/working-hours/views/FlexibleHoursReport.view';

angular
  .module('app')
  .component(
    'flexibleWorkingHours',
    react2angular(FlexibleHoursReportViewComponent, [])
  );
