import React, { useEffect, useState } from 'react';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useExportStore } from '../../../hooks/useExportStore';
import {
  startExport,
  openExportInitiatedModal
} from '../../../utils/export.utils';
import { exportDestinationOptions } from '../utils/exportDestinationOptions';
import { ExportDestination } from '../../../enums/ExportDestination';
import { BaseTooltipSC } from '../styles';
import { ExportsLimitedTooltip } from './ExportsLimitedTooltip';
import { IExportReportParams } from '../../../models/IExport';
import { hasExportsInProgress } from '../../../stores/exportStore';
import authorization from '../../../helpers/authorization';
import { Role } from '../../../enums';

export interface IAsyncExportButtonProps {
  reportType: string;
  createExportPayload: () => IExportReportParams;
  showExportsLimitedTooltip: boolean;
  exportableItemCount?: string;
}

export const AsyncExportButton = (props: IAsyncExportButtonProps) => {
  const {
    reportType,
    exportableItemCount,
    showExportsLimitedTooltip,
    createExportPayload
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [exportInProgress, setExportInProgress] = useState<boolean>(false);
  const exports = useExportStore((s) => s.exports);

  useEffect(() => {
    setExportInProgress(hasExportsInProgress());
  }, [exports]);

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  const handleExportButtonClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSaveExportClick = (exportDestination: ExportDestination) => {
    const payload = createExportPayload();
    startExport(payload, exportDestination);
    setIsMenuOpen(false);
  };

  const handleManageExportClick = () => {
    openExportInitiatedModal();
    setIsMenuOpen(false);
  };

  const exportBtnLabel: string = exportInProgress ? 'Exporting...' : 'Export';

  if (
    authorization.hasAnyRole([
      Role.SupportBasic,
      Role.SupportIntermediate,
      Role.SupportAdvanced
    ])
  ) {
    return <></>;
  }

  return (
    <Box maxWidth="400px" margin="auto">
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <BaseTooltipSC
            PopperProps={{
              disablePortal: true
            }}
            onClose={handleClose}
            open={isMenuOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            sx={{
              [`& .${tooltipClasses.tooltip}`]: {
                maxWidth: '300px'
              }
            }}
            placement="bottom-end"
            title={
              <List component="nav">
                {showExportsLimitedTooltip && (
                  <>
                    <ExportsLimitedTooltip
                      exportableItemCount={exportableItemCount}
                    />
                    <Divider />
                  </>
                )}
                {exportDestinationOptions.map((option) => {
                  return (
                    <ListItemButton
                      key={option.key}
                      sx={{ pl: 4 }}
                      onClick={() => handleSaveExportClick(option.key)}
                      disabled={exportInProgress}
                      id={`36083bf7-6e92-4e8c-bcb3-51e0e14210c4-${reportType}-${option.destinationId}`}
                    >
                      <ListItemIcon>{option.icon}</ListItemIcon>
                      <ListItemText primary={option.label} />
                    </ListItemButton>
                  );
                })}
                {exportInProgress && (
                  <>
                    <Divider />
                    <ListItemButton
                      onClick={handleManageExportClick}
                      sx={{ minWidth: 4 }}
                    >
                      <ListItemText>Manage Export</ListItemText>
                    </ListItemButton>
                  </>
                )}
              </List>
            }
          >
            <Button
              variant="outlined"
              onClick={handleExportButtonClick}
              endIcon={isMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
              id={'487a49f5-14dc-47dc-bf2f-0c68a0b38d89'}
            >
              {exportBtnLabel}
            </Button>
          </BaseTooltipSC>
        </div>
      </ClickAwayListener>
    </Box>
  );
};
