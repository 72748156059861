import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { WhiteBackgroundTooltip } from '../../Tooltip/TextWithTooltip.styles';

interface IExportsLimitedTooltipProps {
  exportableItemCount: string;
}

export const ExportsLimitedTooltip = (props: IExportsLimitedTooltipProps) => {
  const { exportableItemCount } = props;
  return (
    <ListItem>
      <ListItemText>
        Exports Limited
        <WhiteBackgroundTooltip
          title={
            <>
              Exports limited to {exportableItemCount} records. Adjust the
              filters to narrow results.
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                <Link
                  href="https://support.activtrak.com/hc/en-us/articles/360028334711-How-to-Export-and-Manage-Data"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </Link>
              </Box>
            </>
          }
          placement="left"
        >
          <IconButton>
            <InfoOutlinedIcon sx={{ fontSize: 'medium' }} />
          </IconButton>
        </WhiteBackgroundTooltip>
      </ListItemText>
    </ListItem>
  );
};
