import { inMemoryStore } from '../../_ui-utils/src';
import { activTrakConfig } from '../../_reactivtrak/src/common/helpers/activTrakConfig';
import { getApiDomainStore } from '../../_reactivtrak/src/common/stores/apiDomainStore';

if (!activTrakConfig || Object.values(activTrakConfig).length === 0) {
    console.error('ActivTrak Error: Unable to load environment configuration.');
}

class EnvService {
    constructor() {
        // External App URLs
        this.signUpAppUrl = activTrakConfig.signUpAppUrl;
        this.lookerUrl = activTrakConfig.lookerUrl;

        // Environment Variables
        this.environmentName = activTrakConfig.name;
        this.marketoConfig = activTrakConfig.marketo;
    }

    /**
     * @deprecated
     * Use the ApiDomainStore instead.
     * */
    getApiUrl() {
        const { frontendApi } = getApiDomainStore();
        return frontendApi;
    }

    /**
     * @deprecated
     * Use the ApiDomainStore instead.
     * */
    getAuthorizationServiceUrl() {
        const { authorizationService } = getApiDomainStore();
        return authorizationService;
    }

    /**
     * @deprecated
     * Use the ApiDomainStore instead.
     * */
    getReportingServiceUrl() {
        const { reportingService } = getApiDomainStore();
        return reportingService;
    }

    /**
     * @deprecated
     * Use the ApiDomainStore instead.
     * */
    getAccountsApiUrl() {
        const { accountsApi } = getApiDomainStore();
        return accountsApi;
    }

    /**
     * @deprecated
     * Use the ApiDomainStore instead.
     * */
    getWebsocketUrl() {
        const { websocket } = getApiDomainStore();
        return websocket;
    }

    /**
     * @deprecated
     * Use the ApiDomainStore instead.
     * */
    getWebsocketHttpsUrl() {
        const { websocketHttps } = getApiDomainStore();
        return websocketHttps;
    }

    getSignUpAppUrl() {
        return this.signUpAppUrl;
    }

    getEnvironmentName() {
        return this.environmentName;
    }

    getMarketoConfig() {
        return this.marketoConfig;
    }

    /**
     * @deprecated
     * Use the ApiDomainStore instead.
     * */
    getSsoAuthorizationUrl() {
        const { ssoAuthorization } = getApiDomainStore();
        return ssoAuthorization;
    }

    getLookerUrl() {
        return this.lookerUrl;
    }

    /**
     * @deprecated
     * Use the ApiDomainStore instead.
     * */
    getAdminApiUrl() {
        const { adminApi } = getApiDomainStore();
        return adminApi;
    }

    /**
     * @deprecated
     * Use the ApiDomainStore instead.
     * */
    getAccountApiUrl() {
        const { accountApiKeyPage } = getApiDomainStore();
        return accountApiKeyPage;
    }

    /**
     * @deprecated
     * Use the ApiDomainStore instead.
     * */
    getIdentityServiceUrl() {
        const { identityService } = getApiDomainStore();
        return identityService;
    }

    /**
     * @deprecated
     * Use the ApiDomainStore instead.
     * */
    getDefaultUrl() {
        const { defaultDomain } = getApiDomainStore();
        return defaultDomain;
    }
}

const envService = inMemoryStore.get('envService') || new EnvService();
inMemoryStore.set('envService', envService);

export default envService;
