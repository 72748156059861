import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { DownloadProvider } from '../../common/services';
import { useComputerAgentState, UsersProvider } from '../services';
import { Role, SortDirection } from '../../common/enums';
import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';
import { AutocompleteSearchBar } from '../../common/components/AutocompleteSearchBar';
import { TagBar } from '../../common/components/TagBar';
import { AdvancedFilter } from '../../common/components/AdvancedFilter';
import { DownloadAgentButton } from '../../common/components/DownloadAgentButton';
import { DropdownOptionsButton } from '../../common/components/DropdownOptionsButton';
import { DeleteComputersConfirmationModal } from '../components/DeleteComputersConfirmationModal';
import { UninstallComputersConfirmationModal } from '../components/UninstallComputersConfirmationModal';
import { ScheduledDeletesModal } from '../components/ScheduledDeletesModal';
import { ComputerAdvancedSearchForm } from '../components/ComputerAdvancedSearchForm';
import CustomGrid from '../../common/components/Grid/CustomGrid';
import { AgentHealthModal } from '../components/AgentHealthModal';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import GetAppIcon from '@mui/icons-material/GetApp';
import FilterListIcon from '@mui/icons-material/FilterList';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';

import {
  AdvancedFilterButtonContainer,
  ComputerHeaderBarRightActionButtons,
  ComputersGridContainer,
  computersViewStyles as classes,
  HeaderBarActionButtons,
  HeaderBarContainer,
  HeaderBarLeftActionButtons,
  HeaderBarTopComponentsButtons,
  HeaderBarTopComponentsContainer,
  SearchBarContainer,
  TotalsGrouper
} from '../styles';
import { ScheduledDeleteModes } from '../enums';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import {
  computerGridColumns,
  getFilteredComputersForAutocomplete
} from '../utils/ComputerColumns';
import { IAccountSettings } from '../../common/models';
import { localStorageItems } from '../../common/constants';
import { getParsedLocalStorageItem } from '../../common/helpers';
import { useAuthorization } from '../../common/services/Authorization';
import { downloadCSV } from '../utils/computers.utils';

export function ComputersView() {
  const history = useUIRouterHistory();

  const computerAgentState = useComputerAgentState();
  const {
    setGridOrderBy,
    setGridOrderDirection,
    setFilterButtonSelection,
    setSelectedComputers,
    computers,
    selectedComputers,
    refreshComputers,
    uninstallComputersByIds,
    viewIsLoading,
    filteredComputers,
    tabInputData,
    setOpenAgentHealthModal,
    openAgentHealthModal,
    setOpenDeleteComputersConfirmationModal,
    openDeleteComputersConfirmationModal,
    searchTerm,
    setTabInputData,
    onSubmitAdvancedFilter,
    handleDeleteComputersConfirmed,
    filterButtonSelection,
    dropDownFilterButtonOptions,
    uninstallComputerRef,
    openUninstallComputersConfirmationModal,
    setOpenUninstallComputersConfirmationModal,
    agentHealthComputerRef,
    deleteComputerRef
  } = computerAgentState;
  const scheduledDeleteModalRef = useRef(null);
  const accountSettings: IAccountSettings = getParsedLocalStorageItem(
    localStorageItems.accountSettingsStorage
  );

  const authorization = useAuthorization();

  const userIsSuperAdminOrAdmin = authorization.hasAnyRole([
    Role.SuperAdmin,
    Role.Admin
  ]);

  const userIsAdminOrAdvancedSupport = authorization.hasAnyRole([
    Role.SuperAdmin,
    Role.Admin,
    Role.SupportAdvanced
  ]);

  const onSortOrder = useCallback(
    (newOrderDirection: string, newOrderBy: string) => {
      setGridOrderBy(newOrderBy);
      setGridOrderDirection(newOrderDirection);
    },
    []
  );

  const [showAdvancedFilterModal, setShowAdvancedFilterModal] = useState(false);
  const [openScheduledDeletesModal, setOpenScheduledDeletesModal] =
    useState<boolean>(false);

  const handleClickRowGrid = useCallback(
    (rowItem) => {
      history.push('app.settings.computeragents_id', { id: rowItem.id });
    },
    [history]
  );

  const handleComputerGridChange = () => {
    //selectedData comes from the viewable list.  use our own filtered computer list instead
    const gridComputersSelected = computers.filter((item) => item.selected);
    setSelectedComputers(gridComputersSelected);
  };

  const computerGridRowDisableCondition = useCallback((rowItem) => {
    if (rowItem.scheduledActionId === undefined) {
      return false;
    }
    return !(rowItem.scheduledActionId === 0);
  }, []);

  const cancelDeletesCallback = async () => {
    refreshComputers();
  };

  const handleDownload = () => {
    handleOnProgBarStart();
    downloadCSV().then((response) => {
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(
        new Blob([response], { type: 'text/csv;charset=utf-8' })
      );
      reader.onloadend = () => {
        const result = reader.result as string;
        const downloadLink = document.createElement('a');
        downloadLink.href = decodeURIComponent(result);
        downloadLink.download = 'computerAgents.csv';

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        handleOnProgBarEnd();
      };
    });
  };

  const pendingComputerDeletionsExist = useMemo(() => {
    let computerDeletionExists = false;
    computers.forEach((computer) => {
      if (
        computer.scheduledActionId !== undefined &&
        computer.scheduledActionId !== 0
      ) {
        computerDeletionExists = true;
      }
    });
    return computerDeletionExists;
  }, [computers]);

  const handleOnProgBarStart = () => {
    setShowProgbarDwnld(true);
  };

  const handleOnProgBarEnd = () => {
    setShowProgbarDwnld(false);
    setEndProgbarDwnld(true);
  };

  const handleScheduledDeletesClick = async () => {
    setOpenScheduledDeletesModal(true);
    scheduledDeleteModalRef.current.loadScheduledActions();
  };

  const closeDeleteComputersModal = () => {
    setOpenDeleteComputersConfirmationModal(false);
    deleteComputerRef.current = null;
  };

  const [showProgbarDwnld, setShowProgbarDwnld] = useState<boolean>(false);
  const [endProgbarDwnld, setEndProgbarDwnld] = useState<boolean>(false);

  useEffect(() => {
    refreshComputers();
  }, []);

  const resetProgBarEnd = () => {
    setShowProgbarDwnld(false);
    setEndProgbarDwnld(false);
  };

  return (
    <Box>
      {showProgbarDwnld && (
        <div style={classes.dispProgress}>
          <div>Generating Export</div>
          <div style={classes.uploadFileProgressContainer}>
            <div style={classes.uploadFileProgressBar} />
            <LinearProgress />
          </div>
        </div>
      )}
      {endProgbarDwnld && (
        <div style={classes.dispResultBar}>
          <Alert
            variant="filled"
            severity="info"
            sx={classes.confAlert}
            onClose={() => {
              resetProgBarEnd();
            }}
          >
            <p style={{ color: 'black', margin: '0px 0px 0px 0px' }}>
              Download Complete{' '}
            </p>
          </Alert>
        </div>
      )}
      <HeaderBarContainer>
        <HeaderBarTopComponentsContainer>
          <HeaderBarTopComponentsButtons />
          <SearchBarContainer>
            {tabInputData.length ? (
              <TagBar tabBarData={tabInputData} />
            ) : (
              <AutocompleteSearchBar
                autocompleteOptions={getFilteredComputersForAutocomplete(
                  computerAgentState,
                  authorization
                )}
                searchLabelPlaceholder="Search by Primary Domain, Computer, or Computer Alias"
                onChange={computerAgentState.setSearchTerm}
              />
            )}
            {searchTerm ? null : (
              <AdvancedFilterButtonContainer>
                {tabInputData.length ? (
                  <IconButton
                    onClick={() => setTabInputData([])}
                    color="secondary"
                  >
                    <ClearIcon />
                  </IconButton>
                ) : null}
                <AdvancedFilter
                  showModal={showAdvancedFilterModal}
                  setShowModal={setShowAdvancedFilterModal}
                  form={
                    <ComputerAdvancedSearchForm
                      modalData={tabInputData}
                      onSubmit={(formData) => {
                        setShowAdvancedFilterModal(false);
                        onSubmitAdvancedFilter(formData);
                      }}
                    />
                  }
                />
              </AdvancedFilterButtonContainer>
            )}
          </SearchBarContainer>
        </HeaderBarTopComponentsContainer>
        <HeaderBarActionButtons>
          <HeaderBarLeftActionButtons>
            <Button
              startIcon={<DeleteIcon />}
              onClick={() => setOpenDeleteComputersConfirmationModal(true)}
              color="secondary"
              disabled={
                !userIsSuperAdminOrAdmin ||
                selectedComputers == null ||
                selectedComputers?.length == 0
              }
            >
              Delete
            </Button>
            {pendingComputerDeletionsExist && userIsAdminOrAdvancedSupport ? (
              <Button
                startIcon={<DeleteIcon />}
                onClick={handleScheduledDeletesClick}
                color="secondary"
              >
                Scheduled Deletes
              </Button>
            ) : null}
          </HeaderBarLeftActionButtons>
          <ComputerHeaderBarRightActionButtons>
            <TotalsGrouper>
              <span>{`TOTAL COMPUTERS: `}</span>
              {filteredComputers.length}
            </TotalsGrouper>
            <DropdownOptionsButton
              id="68a49dce-0f04-479e-9512-93ace3333a3d"
              icon={<FilterListIcon />}
              indexOption={filterButtonSelection}
              setIndexOption={setFilterButtonSelection}
              dropDownOptions={[
                { label: 'OS' },
                ...dropDownFilterButtonOptions
              ]}
              onChangeOption={(event, option, index) => {
                setFilterButtonSelection(index);
              }}
              disableSelectedItem
            />
            <Button
              startIcon={<GetAppIcon />}
              onClick={handleDownload}
              color="secondary"
            >
              Export
            </Button>
            <DownloadAgentButton />
          </ComputerHeaderBarRightActionButtons>
        </HeaderBarActionButtons>
      </HeaderBarContainer>
      <ComputersGridContainer>
        {(viewIsLoading || filteredComputers.length > 0) && (
          <CustomGrid
            isLoading={viewIsLoading}
            isVirtualized={true}
            data={filteredComputers}
            columns={computerGridColumns(computerAgentState, authorization)}
            onClickRow={handleClickRowGrid}
            uniqueRowProp="id"
            initialSortField="id"
            initialSortDirection={SortDirection.Ascending}
            hasBulkSelection
            featureDisabled={!userIsSuperAdminOrAdmin}
            rowDisableCondition={computerGridRowDisableCondition}
            onSelectAllClick={handleComputerGridChange}
            onSortOrder={onSortOrder}
          />
        )}
        {!viewIsLoading && filteredComputers.length == 0 && (
          <span>{'No computer agents found.'}</span>
        )}
      </ComputersGridContainer>
      <DeleteComputersConfirmationModal
        open={openDeleteComputersConfirmationModal}
        onClose={closeDeleteComputersModal}
        onDelete={() => {
          handleDeleteComputersConfirmed();
        }}
      />
      <UninstallComputersConfirmationModal
        open={openUninstallComputersConfirmationModal}
        onClose={() => setOpenUninstallComputersConfirmationModal(false)}
        computer={uninstallComputerRef.current}
        onDelete={() =>
          uninstallComputersByIds(
            [uninstallComputerRef.current],
            accountSettings
          )
        }
      />
      <AgentHealthModal
        open={openAgentHealthModal}
        selectedComputer={agentHealthComputerRef.current}
        onClose={() => setOpenAgentHealthModal(false)}
      />
      <ScheduledDeletesModal
        mode={ScheduledDeleteModes.Computers}
        open={openScheduledDeletesModal}
        onClose={() => setOpenScheduledDeletesModal(false)}
        onCancel={cancelDeletesCallback}
        ref={scheduledDeleteModalRef}
      />
    </Box>
  );
}

export const ComputersViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <DownloadProvider>
      <UsersProvider>
        <ComputersView />
      </UsersProvider>
    </DownloadProvider>
  </ReactivTrakComponentWrapper>
);
