import React, { useEffect, useState } from 'react';
import { ColDef } from 'ag-grid-community';
import { DownloadProvider } from '../../../common/services';
import { WorkingHoursReportNewGridHeader } from '../components/WorkingHoursReportNewGridHeader';
import { getScheduledColumns } from '../utils/workingHoursReport.utils';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import {
  IWorkingHoursReportUserDto,
  WorkingHoursReportSections
} from '../models/WorkingHoursTypes';
import { generateParameters } from '../../../common/components/ReportFilters/utils/generateParameters';
import { useScheduledWorkingHoursReportState } from '../hooks/useScheduledWorkingHoursReportState';
import { useRefreshState } from '../../../common/components/ReportFilters/hooks';
import { mergeArrayOfObjects } from '../../../common/helpers';
import { NoReportData } from '../../common/components/NoReportData';
import AtGrid from '../../../common/components/AtGrid/components/AtGrid';
import { ReactivTrakComponentWrapper } from '../../../ReactivTrakComponentWrapper';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export const ScheduledHoursReportView = () => {
  const [selectedColumnFields, setSelectedColumnFields] =
    useState<ColDef<IWorkingHoursReportUserDto>[]>();
  const [view, setView] = useState<
    WorkingHoursReportSections.SUMMARY | WorkingHoursReportSections.DETAILED
  >(WorkingHoursReportSections.SUMMARY);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

  const { refreshTimestamp, handleRefresh } = useRefreshState();

  //   const { calendarIntegrated } = useInsightsSettingsStore();

  useEffect(() => {
    if (refreshTimestamp) {
      setIsRefreshing(true);
      setTimeout(() => {
        setIsRefreshing(false);
      }, 5000);
    }
  }, [refreshTimestamp]);

  //TODO: 'view' needs to be passed to the api too
  const reportFiltersStr = generateParameters(reportFilters, {});

  const { data, isLoading, init } = useScheduledWorkingHoursReportState();

  useEffect(() => {
    const columns = getScheduledColumns({ view });
    if (columns) {
      // FUTURE: update 'hidden' statuses based on persisted values
      setSelectedColumnFields(columns);
    }
  }, [view]);

  useEffect(() => {
    init(reportFiltersStr);
  }, [init, reportFiltersStr]);

  const handleToggleChange = (e) => {
    const targetMode: WorkingHoursReportSections = e.target.value;
    setView(targetMode);
  };

  const handleColumnsSubmit = (columns: ColDef[]): void => {
    /**
     * Merge the currently selected columns with the current state
     */
    setSelectedColumnFields((prevState) =>
      mergeArrayOfObjects(prevState, columns, 'field')
    );
  };

  const handleRestoreColumns = () => {
    const columns = getScheduledColumns({ view });
    setSelectedColumnFields(columns);
  };

  return (
    <>
      <WorkingHoursReportNewGridHeader
        reportFilters={reportFilters}
        onRefresh={handleRefresh}
        isRefreshing={isRefreshing}
        refreshTimestamp={refreshTimestamp}
        selectedColumns={selectedColumnFields}
        onSubmitColumns={handleColumnsSubmit}
        onRestoreDefaults={handleRestoreColumns}
      />
      <Box sx={{ mt: 1, mb: 2 }}>
        <ToggleButtonGroup
          color="primary"
          value={view}
          exclusive
          onChange={handleToggleChange}
          aria-label="Platform"
        >
          <ToggleButton value={WorkingHoursReportSections.SUMMARY}>
            Summary
          </ToggleButton>
          <ToggleButton value={WorkingHoursReportSections.DETAILED}>
            Detailed
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {selectedColumnFields && (
        <AtGrid
          data={data}
          selectedColumnFields={selectedColumnFields}
          isLoading={isLoading}
          gridOptionOverrides={{ noRowsOverlayComponent: NoReportData }}
        />
      )}
    </>
  );
};

export const ScheduledHoursReportViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <DownloadProvider>
      <ScheduledHoursReportView />
    </DownloadProvider>
  </ReactivTrakComponentWrapper>
);
