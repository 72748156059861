import { postData } from '../helpers';
import { userServiceFunctions } from '../../../../_app/serviceFunctions/userServiceFunctions.js';

import { localStorageItems } from '../constants';
import { postLoginStore } from '../stores/postLoginStore';
import { setAccountSettings } from '../helpers/accountSettings/accountSettingsStore';

interface IChildAccountToken {
  token: string;
  account: {
    email: string;
    firstName?: string;
    lastName?: string;
    organization?: string;
    licenseCount?: number;
    isConfigured: boolean;
    referrer?: string;
    accountWizard?: string;
  };
}

const _parse = (json: string) => {
  let result = json;
  for (let count = 0; count < 2; count++) {
    result = JSON.parse(result);
    if (typeof result !== 'string') {
      break;
    }
  }

  if (typeof result === 'string') {
    return null;
  }

  return result;
};

export const restore = async () => {
  const msp = window.localStorage.getItem(
    localStorageItems.commandCenterBackupToken
  );

  if (!msp) {
    return;
  }

  const { currentUser } = _parse(msp);
  userServiceFunctions.setUserToken(currentUser.token);

  window.localStorage.setItem(localStorageItems.globalSettingsStorage, msp);
  window.localStorage.removeItem(localStorageItems.commandCenterBackupToken);

  const accountSettings = await postLoginStore.getState().refresh();
  setAccountSettings(accountSettings);
};

export const impersonate =
  (accountId: string) => async () => {
    //Debating if need to couple both _restore and _impersonate in a tuple...
    const backup = window.localStorage.getItem(
      localStorageItems.globalSettingsStorage
    );
    window.localStorage.setItem(
      localStorageItems.commandCenterBackupToken,
      backup
    );

    const response = await postData<IChildAccountToken>({
      path: '/account/child/token',
      args: {
        accountId
      }
    });

    const globals = {
      currentUser: {
        token: response.token,
        username: response.account?.email
      }
    };

    userServiceFunctions.setUserToken(response.token);
    window.localStorage.setItem(
      localStorageItems.globalSettingsStorage,
      JSON.stringify(globals)
    );


    const accountSettings = await postLoginStore.getState().refresh();
    setAccountSettings(accountSettings);

    return response;
  };

export const useImpersonate = (
  accountId?: string
): [() => Promise<void>] | [() => Promise<void>, () => Promise<IChildAccountToken>] => {

  if (!accountId) {
    return [restore];
  }

  return [restore, impersonate(accountId)];
};
