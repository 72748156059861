export enum DateFormat {
  MonthFirstFormat = 'MM/DD/YYYY', // Original value was 'MM/dd/yyyy' Kendo format
  DayFirstFormat = 'DD/MM/YYYY', // Original value was 'dd/MM/yyyy' Kendo format
  ApiDateFormat = 'YYYY-MM-DD'
}

export enum TimeFormat {
  TwelveHour = 'hh:mm:ss A', // Original value was 'hh:mm:ss tt' Kendo format
  TwentyFourHour = 'HH:mm:ss'
}
