import { Role, RoleAccessKeys } from '../../../../../../enums';
import { IRoute } from '../../../../../../models/IRoute';
import { BundleFlag } from '../../../../../../enums/BundleFlag';
import { refreshInsightsSettings } from '../../../../../../stores/insightsSettingsStore';

export const appReportsWorkingHours: IRoute = {
  name: 'app.reports.workingHours',
  stateDefinition: {
    url: '/workinghours',

    component: 'workingHoursReport',
    resolve: {
      loadInsightsSettingsStore: [async () => await refreshInsightsSettings()]
    },
    data: {
      pageTitle: 'Working Hours Report',
      filter: { export: true, columns: true },
      exportText: 'export',
      reportName: 'workingHoursReportName'
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.WorkingHours,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.WorkingHoursReport]
  }
};

export default appReportsWorkingHours;
