import { useCallback, useRef, useState } from 'react';
import {
  ITodaysUserActivityDetailsDto,
  ITodaysUserActivityDto,
  ITodaysUserActivityState,
  ITodaysUserActivityTeamUser
} from '../models';
import { ApiRoutes } from '../constants';
import {
  getParsedLocalStorageItem,
  getProductivity
} from '../../common/helpers';
import { localStorageItems } from '../../common/constants';
import { IAccountSettings } from '../../common/models';
import { buildReportsApiRequest } from '../utils';
import { IReportFilters } from '../../common/components/ReportFilters/models/IReportFilters';
import { formatDateByTimezone } from '../../common/utils/datetime/datetimeFormatters';

export const useTodaysUserActivityState = (): ITodaysUserActivityState => {
  const [todaysUserActivity, setTodaysUserActivity] =
    useState<ITodaysUserActivityDto>();
  const [originalDayData, setOriginalDayData] =
    useState<ITodaysUserActivityTeamUser[]>();

  const [chartDate, setChartDate] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChartLoading, setIsChartLoading] = useState<boolean>(false);
  const [isTileError, setIsTileError] = useState<boolean>(false);
  const [isChartError, setIsChartError] = useState<boolean>(false);

  const accountSettings: IAccountSettings = getParsedLocalStorageItem(
    localStorageItems.accountSettingsStorage
  );

  const accountTimezone = useRef(accountSettings.currentIanaTimeZone);

  const getTodaysActivityData = useCallback(
    async (reportFilters: IReportFilters): Promise<void> => {
      setIsLoading(true);

      try {
        const response = await buildReportsApiRequest<ITodaysUserActivityDto>({
          path: ApiRoutes.reports.fetchTodaysUserActivity,
          params: { groupId: reportFilters.groupId[0] }
        });
        setTodaysUserActivity(response);
      } catch (error) {
        console.error(
          "ActivTrak Error: Unable to load today's activity data =>",
          error
        );
        setIsTileError(true);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  //include groups with params
  const getChartData = useCallback(async (filters): Promise<void> => {
    setIsChartLoading(true);
    setIsChartError(false);

    try {
      const response =
        await buildReportsApiRequest<ITodaysUserActivityDetailsDto>({
          path: ApiRoutes.reports.fetchTodaysUserActivityDetails,
          params: filters
        });

      const formattedData = response.teamResults.map((details) => ({
        user: details.userName,
        productivityStatus: getProductivity(details.productivityStatus),
        activity: details.activity,
        startTime: details.startTime,
        endTime: details.endTime,
        hasDescription: details.hasDescription,
        isWebsite: details.isWebsite
      }));

      const date = formatDateByTimezone(response.lastUpdated);
      setOriginalDayData(formattedData);
      setChartDate(date);
    } catch (error) {
      console.error(
        "ActivTrak Error: Unable to load today's activity data details =>",
        error
      );
      setIsChartError(true);
    } finally {
      setIsChartLoading(false);
    }
  }, []);

  return {
    isLoading,
    isTileError,
    todaysUserActivity,
    isChartLoading,
    originalDayData,
    accountTimezone,
    chartDate,
    getTodaysActivityData,
    getChartData,
    isChartError
  };
};
