import {
  IComputerDto,
  IDeviceV1Dto,
  IComputer,
  IComputerAgentHealth,
  IHealthCheck,
  IUserAgentHealth,
  IComputerAgentHealthDto,
  IHealthCheckDto,
  IUserAgentHealthDto,
  IComputerDetail,
  IAgentLogSettings,
  IAgentSettings,
  IScheduledAction,
  IAgentCommandDto,
  IAgentCommand,
  IAgentStatus,
  IAgentStatusDto,
  IComputerDetailDto
} from '../models';

import { formatComputerComplexName } from './';

export const mapToComputers = (computers: IComputerDto[]): IComputer[] => {
  return computers?.map((row) => {
    const {
      compid,
      agentversion,
      windowsdomain,
      firstlog,
      lastlog,
      lcount,
      scount,
      scheduledActionId,
      alias,
      computer,
      lastss,
      storage
    } = row;

    return {
      id: compid,
      agentVersion: agentversion,
      windowsDomain: windowsdomain,
      firstLog: firstlog,
      lastLog: lastlog,
      logsCount: lcount,
      screenShotCount: scount,
      os: getComputerOS(row),
      alias,
      computer,
      lastss,
      storage,
      scheduledActionId,
      selected: false,
      domainComputerAlias: formatComputerComplexName(
        windowsdomain,
        computer,
        alias
      )
    };
  });
};

export const mapDeviceV1ToComputers = (
  computers: IDeviceV1Dto[]
): IComputer[] => {
  return computers?.map((row) => {
    const {
      id,
      domain,
      name,
      alias,
      version,
      firstActivity,
      lastActivity,
      deleteDate,
      lastScreenshot
    } = row;

    return {
      id: id,
      agentVersion: version,
      windowsDomain: domain,
      firstLog: firstActivity,
      lastLog: lastActivity,
      logsCount: null,
      screenShotCount: null,
      os: getComputerOS({
        windowsdomain: domain,
        computer: name
      } as IComputerDto),
      alias: alias,
      computer: name,
      lastss: lastScreenshot,
      storage: null,
      scheduledActionId: deleteDate ? 1 : 0,
      selected: false,
      domainComputerAlias: formatComputerComplexName(domain, name, alias)
    };
  });
};

export function mapDeviceV1ToScheduledActionDto(
  actions: IDeviceV1Dto[]
): IScheduledAction[] {
  return actions
    ?.filter((d) => d.deleteDate)
    .map((row) => {
      const { id, domain, name } = row;

      return {
        id: id,
        typeid: id,
        typename: 'computer',
        actionname: null,
        description: null,
        scheduledtime: null,
        completedtime: null,
        filter: null,
        primary: domain,
        secondary: name,
        dntid: null
      };
    });
}

export const mapToAgentHealth = (
  computers: IComputerAgentHealthDto[]
): IComputerAgentHealth[] => {
  return computers?.map((row) => {
    const { name, osVersion, users } = row;

    return {
      name: name,
      osVersion: osVersion,
      users: mapToUserAgentHealth(users)
    };
  });
};

export const mapToUserAgentHealth = (
  users?: IUserAgentHealthDto[]
): IUserAgentHealth[] => {
  return users?.map((row) => {
    const { name, logonDomain, agentVersion, time, healthChecks } = row;

    return {
      name: name,
      logonDomain: logonDomain,
      agentVersion: agentVersion,
      time: time,
      healthChecks: mapToHealthChecks(healthChecks)
    };
  });
};

export const mapToAgentLogSettings = (
  logSettings: any[]
): IAgentLogSettings[] => {
  return logSettings?.map((log) => {
    const { name, level } = log;
    return {
      name: name,
      level: level
    };
  });
};

export const mapToAgentSettings = (agentSettings: any): IAgentSettings => {
  const { logSettings, accountId, computerId } = agentSettings;
  const newLogSettings = mapToAgentLogSettings(logSettings);
  const newSettings = {
    accountId: accountId,
    computerId: computerId,
    logSettings: newLogSettings
  };
  return newSettings;
};

export const mapToHealthChecks = (
  healthchecks?: IHealthCheckDto[]
): IHealthCheck[] => {
  return healthchecks?.map((row) => {
    const { name, status, message } = row;

    return {
      name: name,
      status: status,
      message: message
    };
  });
};

export const mapToComputerDetail = (
  computerDetail: IComputerDetailDto
): IComputerDetail => {
  const { compid, lcount, sstorage, vstorage, scount, vcount } = computerDetail;

  return {
    id: compid,
    videoStorage: vstorage,
    screenShotStorage: sstorage,
    logCount: lcount,
    screenShotCount: scount,
    videoCount: vcount,

    firstLog: computerDetail.firstlog,
    lastLog: computerDetail.lastlog,
    os: computerDetail.os,
    windowsDomain: computerDetail.windowsdomain,
    computerName: computerDetail.computer,
    alias: computerDetail.alias,
    agentVersion: computerDetail.agentversion
  };
};

export const getComputerOS = (computer: IComputerDto): string => {
  if (!computer.windowsdomain) {
    return 'Apple';
  } else if (
    computer.computer.lastIndexOf('CA_', 0) === 0 &&
    computer.windowsdomain === 'Google'
  ) {
    return 'Chrome';
  } else {
    return 'Windows';
  }
};

const formatType = (type: string) => {
  //add spaces to camelCase types
  const flatFormat = type?.replace(/([a-z])([A-Z])/g, '$1 $2');
  //don't separate ActivTrak
  if (flatFormat && flatFormat.indexOf('Activ Trak') > -1) {
    return flatFormat.replace('Activ Trak', 'ActivTrak');
  }
  return flatFormat;
};

const parseJSONObject = (jsonString: string) => {
  try {
    const parsedObject = jsonString?.startsWith('{')
      ? JSON.parse(jsonString)
      : null;
    if (parsedObject && typeof parsedObject === 'object') {
      return parsedObject;
    }
  } catch (e) {
    return null;
  }
  return null;
};

export const mapToAgentCommand = (
  agentCommands: IAgentCommandDto[]
): IAgentCommand[] => {
  return agentCommands?.map((row, index) => {
    const { id, status, creationTime, accountId, code, computerId } = row;
    const newStatus = mapToStatus(status);

    return {
      status: newStatus,
      creationTime: creationTime,
      accountId: accountId,
      code: code,
      computerId: computerId,
      uniqueRowId: id.toString() + '-' + index.toString(),
      codeFormatted: formatType(code)
    };
  });
};

export const mapToStatus = (status: IAgentStatusDto): IAgentStatus => {
  if (status) {
    return {
      outcome: status.outcome,
      message: status.message,
      executionTime: status.executionTime,
      receivedTime: status.receivedTime,
      parsedStatusMessage: parseJSONObject(status.message)
    };
  }
  return null;
};
