import { resetReportFilters } from '../../components/ReportFilters/hooks/reportFiltersStore';
import { userPermissionStore } from '../../hooks/userPermissionStore';
import { accountSettingsStore } from '../../../common/helpers/accountSettings/accountSettingsStore';
import { resetEntitiesStore } from '../../hooks/entityStore';
import { resetPrivacySettingsStore } from '../../hooks/privacySettingsStore';
import { resetAccountPlanStore } from '../../stores/accountPlanStore';
import { resetStoreCache } from '../../utils/storeCacheHandler';
import { clearAndResetExportStore } from '../../utils/export.utils';
import { resetRealtimeSettingsDtoCache } from '../../caches/realtimeSettingsDtoCache';
import { resetRealtimeSettingsStore } from '../../stores/realtimeSettingsStore';
import { resetWebsocketSettingsStore } from '../../stores/websocketSettingsStore';
import { resetScreenshotSettingsStore } from '../../stores/screenshotSettingsStore';
import { postLoginStore } from '../../stores/postLoginStore';
import { resetScreenshotRedactionSettingsStore } from '../../stores/screenshotRedactionSettingsStore';
import { resetScreenshotSafeSearchSettingsStore } from '../../stores/screenshotSafeSearchSettingsStore';
import { resetLiveReportSettingsStore } from '../../stores/liveReportsSettingsStore';
import { resetDateSettingsStore } from '../../stores/dateSettingsStore';
import { resetInsightsSettingsStore } from '../../stores/insightsSettingsStore';
import { resetApiDomainStore } from '../../stores/apiDomainStore';
import { resetGlobalModalStore } from '../../stores/globalModalStore';
import { resetIdentitySearchStore } from '../../stores/identitySearchStore';

export const resetStores = (): void => {
  resetStoreCache();
  resetReportFilters();
  resetEntitiesStore();
  resetPrivacySettingsStore();
  resetAccountPlanStore();
  resetRealtimeSettingsDtoCache();
  resetRealtimeSettingsStore();
  resetWebsocketSettingsStore();
  resetScreenshotSettingsStore();
  resetScreenshotRedactionSettingsStore();
  resetScreenshotSafeSearchSettingsStore();
  resetDateSettingsStore();
  resetLiveReportSettingsStore();
  resetInsightsSettingsStore();
  resetApiDomainStore();
  resetGlobalModalStore();
  clearAndResetExportStore();
  postLoginStore.getState().reset();
  accountSettingsStore.getState().reset();
  userPermissionStore.getState().reset();
  resetIdentitySearchStore();
};

export const logout = (params: Record<string, string> | null): void => {
  resetStores();

  // Force a reload to clear all state and check for new app versions
  const queryParams = params
    ? `?${new URLSearchParams(params).toString()}`
    : '';
  window.location.href = `#/login${queryParams}`;
  window.location.reload();
};
