import angular from 'angular';
import { react2angular } from '../common/third-party/react2angular';
import { ScheduledHoursReportViewComponent } from '../reports/working-hours/views/ScheduledHoursReport.view';

angular
  .module('app')
  .component(
    'scheduledWorkingHours',
    react2angular(ScheduledHoursReportViewComponent, [])
  );
