import React from 'react';
import { ColDef, ITooltipParams } from 'ag-grid-community';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import { FeatureFlag } from '../../../common/enums/FeatureFlag';
import {
  formatDuration,
  formatTimeStamp,
  htmlEscape
} from '../../../common/helpers';
import { formatDate } from '../../../common/utils/datetime/datetimeFormatters';
import authorization from '../../../common/helpers/authorization';
import {
  stopLightProductivityColors,
  secondaryFontSize
} from '../../../common/constants';
import { LocationHeader } from '../components/LocationHeader';
import { OnlineMeetingsHeader } from '../components/OfflineMeetingsHeader';
import Link from '../../../common/components/Link';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import {
  WhrColumnsProps,
  WorkingHoursReportSections
} from '../models/WorkingHoursTypes';
import { CustomHeaderTemplate } from '../../../common/components/AtGrid/components/CustomHeaderTemplate';
import { isTodayByTimezone } from '../../../common/utils/datetime/isToday';

const getAccessForFeature = (dataBundleFlag, promoBundleFlag, featureFlag) => {
  const hasDataBundleFlag = authorization.hasFeature(dataBundleFlag);
  const hasPromoBundleFlag = authorization.hasFeature(promoBundleFlag);
  const hasFeatureFlag = authorization.hasFeature(featureFlag);
  const canAccessUpgrade = authorization.hasRouteByName('app.account.upgrade');

  const hasColumnBundleFlag =
    hasDataBundleFlag || (hasPromoBundleFlag && canAccessUpgrade);

  return hasColumnBundleFlag && hasFeatureFlag;
};

export const getHasFeature = (feature: BundleFlag): boolean =>
  authorization.hasFeature(feature);

const flexibleColumnViews: Record<
  WorkingHoursReportSections.SUMMARY | WorkingHoursReportSections.DETAILED,
  string[]
> = {
  [WorkingHoursReportSections.SUMMARY]: [
    'date',
    'users',
    'location',
    'productiveTime',
    'unproductiveTime',
    'undefinedTime',
    'totalTime',
    'totalTimeOffline'
  ],
  [WorkingHoursReportSections.DETAILED]: [
    'date',
    'users',
    'location',
    'productiveActiveTime',
    'productivePassiveTime',
    'unproductiveActiveTime',
    'unproductivePassiveTime',
    'undefinedActiveTime',
    'undefinedPassiveTime',
    'totalTime',
    'totalTimeOffline'
  ]
};

export const getFlexibleColumns = (props: WhrColumnsProps): ColDef[] => {
  const { view } = props;

  //NOTE: Because the Columns Selector uses the 'hide' property to determine if a column is visible, a 'hasAccess' property is used to separately determine if a column should be included in the total list of columns to display.
  const cols = [
    {
      field: 'uniqueId',
      type: 'string',
      hide: true
    },
    {
      field: 'date',
      headerName: 'Date',
      type: 'date',
      sortable: false,
      valueFormatter: (params) => formatDate(params.value),
      minWidth: 150,
      hide: false
    },
    {
      field: 'users',
      headerName: 'User',
      type: 'string',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params.data;
        return generateListOfItemsLinked(dataItem.users, 'user');
      },
      tooltipValueGetter: (params: ITooltipParams) => params.value,
      minWidth: 180,
      hide: false
    },
    {
      field: 'location',
      headerName: 'Location',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: <LocationHeader />
      },
      type: 'string',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params.data;
        const hasFeatureEnabled = authorization.hasFeature(
          BundleFlag.LocationData
        );
        const isTodayDate = isTodayByTimezone(dataItem.date);
        if (!dataItem.location || !hasFeatureEnabled || isTodayDate) return '';

        return dataItem.location;
      },
      minWidth: 150,
      cellStyle: { textAlign: 'center' },
      hide: false,
      hasAccess: getAccessForFeature(
        BundleFlag.LocationData,
        BundleFlag.LocationPromo,
        FeatureFlag.ShowLocationData
      )
    },
    {
      field: 'firstActivity',
      headerName: 'First Activity',
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            First
            <br />
            Activity
          </Typography>
        )
      },
      type: 'date',
      sortable: false,
      valueFormatter: (params) => formatTimeStamp(params.value, 'hh:mm:ss A'),
      cellStyle: { textAlign: 'center' },
      minWidth: 150,
      hide: false
    },
    {
      field: 'lastActivity',
      headerName: 'Last Activity',
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Last
            <br />
            Activity
          </Typography>
        )
      },
      type: 'date',
      sortable: false,
      valueFormatter: (params) => formatTimeStamp(params.value, 'hh:mm:ss A'),
      cellStyle: { textAlign: 'center' },
      minWidth: 150,
      hide: false
    },
    {
      field: 'productiveTime',
      headerName: 'Productive',
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        return getProductivityTimeTemplate(params.data.productiveTime, 1);
      },
      cellStyle: { alignContent: 'center' },
      minWidth: 150,
      hide: false
    },
    {
      field: 'productiveActiveTime',
      headerName: 'Productive Active',
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Productive
            <br />
            Active
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        return getProductivityTimeTemplate(params.data.productiveActiveTime, 1);
      },
      cellStyle: { alignContent: 'center' },
      minWidth: 150,
      hide: true
    },
    {
      field: 'productivePassiveTime',
      headerName: 'Productive Passive',
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Productive
            <br />
            Passive
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        return getProductivityTimeTemplate(
          params.data.productivePassiveTime,
          2
        );
      },
      cellStyle: { alignContent: 'center' },
      minWidth: 150,
      hide: true
    },
    {
      field: 'unproductiveTime',
      headerName: 'Unproductive',
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        return getProductivityTimeTemplate(params.data.unproductiveTime, -1);
      },
      cellStyle: { alignContent: 'center' },
      minWidth: 150,
      hide: false
    },
    {
      field: 'unproductiveActiveTime',
      headerName: 'Unproductive Active',
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Unproductive
            <br />
            Active
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        return getProductivityTimeTemplate(
          params.data.unproductiveActiveTime,
          -1
        );
      },
      cellStyle: { alignContent: 'center' },
      minWidth: 150,
      hide: true
    },
    {
      field: 'unproductivePassiveTime',
      headerName: 'Unproductive Passive',
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Unproductive
            <br />
            Passive
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        return getProductivityTimeTemplate(
          params.data.unproductivePassiveTime,
          -2
        );
      },
      cellStyle: { alignContent: 'center' },
      minWidth: 150,
      hide: true
    },
    {
      field: 'undefinedTime',
      headerName: 'Undefined',
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        return getProductivityTimeTemplate(params.data.undefinedTime, 0);
      },
      cellStyle: { alignContent: 'center' },
      width: 150,
      hide: false
    },
    {
      field: 'undefinedActiveTime',
      headerName: 'Undefined Active',
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Undefined
            <br />
            Active
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        return getProductivityTimeTemplate(params.data.undefinedActiveTime, 0);
      },
      cellStyle: { alignContent: 'center' },
      width: 150,
      hide: true
    },
    {
      field: 'undefinedPassiveTime',
      headerName: 'Undefined Passive',
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Undefined
            <br />
            Passive
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        return getProductivityTimeTemplate(
          params.data.undefinedPassiveTime,
          -3
        );
      },
      cellStyle: { alignContent: 'center' },
      minWidth: 150,
      hide: true
    },
    {
      field: 'totalTime',
      headerName: 'Total Time',
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Total
            <br />
            Time
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      valueFormatter: (params) => formatDuration(params.data.totalTime),
      minWidth: 100,
      hide: false
    },
    {
      field: 'totalTimeOffline',
      headerName: 'Offline Meetings',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: <OnlineMeetingsHeader />
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params.data;
        const hasFeatureEnabled = authorization.hasFeature(
          BundleFlag.OfflineMeetingsData
        );
        const isTodayDate = isTodayByTimezone(dataItem.date);
        if (!hasFeatureEnabled || isTodayDate) return '';

        return getProductivityTimeTemplate(dataItem.totalTimeOffline || 0, -4);
      },
      cellStyle: { alignContent: 'center' },
      minWidth: 150,
      hide: false,
      hasAccess: getAccessForFeature(
        BundleFlag.OfflineMeetingsData,
        BundleFlag.OfflineMeetingsPromo,
        FeatureFlag.ShowOfflineMeetingData
      )
    }
  ];

  const selectedFields = flexibleColumnViews[view];
  const selectedCols = cols.filter((col) => selectedFields.includes(col.field));

  //KNOWN: Using 'hasAccess' triggers a typescript warning that it is not a valid property of ColDef. But this is easier than adding a separate function to insert the allowed fields into a specific index of a dynamic array.
  return selectedCols.filter((col) => col.hasAccess !== false);
};

const scheduledColumnViews: Record<
  WorkingHoursReportSections.SUMMARY | WorkingHoursReportSections.DETAILED,
  string[]
> = {
  [WorkingHoursReportSections.SUMMARY]: [
    'date',
    'users',
    'location',
    'productiveTime',
    'unproductiveTime',
    'undefinedTime',
    'totalTime'
  ],
  [WorkingHoursReportSections.DETAILED]: [
    'date',
    'users',
    'location',
    'productiveTime',
    'firstActivity',
    'lastActivity',
    'totalTime'
  ]
};

export const getScheduledColumns = (props: WhrColumnsProps): ColDef[] => {
  const { view } = props;

  //NOTE: Because the Columns Selector uses the 'hide' property to determine if a column is visible, a 'hasAccess' property is used to separately determine if a column should be included in the total list of columns to display.
  const cols = [
    {
      field: 'uniqueId',
      type: 'string',
      hide: true
    },
    {
      field: 'date',
      headerName: 'Date',
      type: 'date',
      sortable: false,
      valueFormatter: (params) => formatDate(params.value),
      minWidth: 150,
      hide: false
    },
    {
      field: 'users',
      headerName: 'User',
      type: 'string',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params.data;
        return generateListOfItemsLinked(dataItem.users, 'user');
      },
      tooltipValueGetter: (params: ITooltipParams) => params.value,
      minWidth: 180,
      hide: false
    },
    {
      field: 'location',
      headerName: 'Location',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: <LocationHeader />
      },
      type: 'string',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params.data;
        const hasFeatureEnabled = authorization.hasFeature(
          BundleFlag.LocationData
        );
        const isTodayDate = isTodayByTimezone(dataItem.date);
        if (!dataItem.location || !hasFeatureEnabled || isTodayDate) return '';

        return dataItem.location;
      },
      minWidth: 150,
      cellStyle: { textAlign: 'center' },
      hide: false,
      hasAccess: getAccessForFeature(
        BundleFlag.LocationData,
        BundleFlag.LocationPromo,
        FeatureFlag.ShowLocationData
      )
    },
    {
      field: 'firstActivity',
      headerName: 'First Activity',
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            First
            <br />
            Activity
          </Typography>
        )
      },
      type: 'date',
      sortable: false,
      valueFormatter: (params) => formatTimeStamp(params.value, 'hh:mm:ss A'),
      cellStyle: { textAlign: 'center' },
      minWidth: 150,
      hide: false
    },
    {
      field: 'lastActivity',
      headerName: 'Last Activity',
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Last
            <br />
            Activity
          </Typography>
        )
      },
      type: 'date',
      sortable: false,
      valueFormatter: (params) => formatTimeStamp(params.value, 'hh:mm:ss A'),
      cellStyle: { textAlign: 'center' },
      minWidth: 150,
      hide: false
    },
    {
      field: 'productiveTime',
      headerName: 'Productive',
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        return getProductivityTimeTemplate(params.data.productiveTime, 1);
      },
      cellStyle: { alignContent: 'center' },
      minWidth: 150,
      hide: false
    },
    {
      field: 'unproductiveTime',
      headerName: 'Unproductive',
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        return getProductivityTimeTemplate(params.data.unproductiveTime, -1);
      },
      cellStyle: { alignContent: 'center' },
      minWidth: 150,
      hide: false
    },
    {
      field: 'undefinedTime',
      headerName: 'Undefined',
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        return getProductivityTimeTemplate(params.data.undefinedTime, 0);
      },
      cellStyle: { alignContent: 'center' },
      minWidth: 150,
      hide: false
    },
    {
      field: 'totalTime',
      headerName: 'Total Time',
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Total
            <br />
            Time
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => formatDuration(params.data.totalTime),
      minWidth: 100,
      hide: false
    },
    {
      field: 'totalTimeOffline',
      headerName: 'Offline Meetings',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: <OnlineMeetingsHeader />
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params.data;
        const hasFeatureEnabled = authorization.hasFeature(
          BundleFlag.OfflineMeetingsData
        );
        const isTodayDate = isTodayByTimezone(dataItem.date);
        if (!hasFeatureEnabled || isTodayDate) return '';

        return getProductivityTimeTemplate(dataItem.totalTimeOffline || 0, -4);
      },
      cellStyle: { alignContent: 'center' },
      minWidth: 150,
      hide: false,
      hasAccess: getAccessForFeature(
        BundleFlag.OfflineMeetingsData,
        BundleFlag.OfflineMeetingsPromo,
        FeatureFlag.ShowOfflineMeetingData
      )
    }
  ];

  const selectedFields = scheduledColumnViews[view];
  const selectedCols = cols.filter((col) => selectedFields.includes(col.field));

  //KNOWN: Using 'hasAccess' triggers a typescript warning that it is not a valid property of ColDef. But this is easier than adding a separate function to insert the allowed fields into a specific index of a dynamic array.
  return selectedCols.filter((col) => col.hasAccess !== false);
};

const generateListOfItemsLinked = (
  array: string[],
  type: string
): React.ReactNode => {
  if (!array || typeof array.forEach !== 'function') {
    return <></>;
  }

  const item = array[0];

  const escapedName = htmlEscape(item);
  const query = {};
  query[type] = escapedName;

  return (
    <Link to="app.reports.activitylog" params={query}>
      {item}
    </Link>
  );
};

const getProductivityTimeTemplate = (duration = 0, productivity) => {
  let color: string;
  switch (productivity) {
    case 1:
      color = stopLightProductivityColors.productive;
      break;
    case -1:
      color = stopLightProductivityColors.unproductive;
      break;
    case -3:
      color = stopLightProductivityColors.undefinedPassive;
      break;
    case 2:
      color = stopLightProductivityColors.productivePassive;
      break;
    case -2:
      color = stopLightProductivityColors.unproductivePassive;
      break;
    case -4:
    case 9:
      color = stopLightProductivityColors.offlineMeetings;
      break;
    default:
      color = stopLightProductivityColors.undefined;
  }
  const productivityBubble = (
    <CircleIcon sx={{ color, fontSize: secondaryFontSize }} />
  );

  return (
    <Typography>
      {formatDuration(duration)} {productivityBubble}
    </Typography>
  );
};

