import { useCallback, useState } from 'react';
import { fetchReportingData } from '../../../common/helpers';
import {
  IWorkingHoursReportState,
  IWorkingHoursReportUserDto,
  IWorkingHoursReportUsersDto
} from '../models/WorkingHoursTypes';

export const useScheduledWorkingHoursReportState =
  (): IWorkingHoursReportState => {
      const [data, setData] = useState<IWorkingHoursReportUserDto[]>();
      const [isLoading, setIsLoading] = useState<boolean>(false);

      const init = useCallback(
        async (reportFiltersStr: string): Promise<void> => {
          setIsLoading(true);
          try {
            const response =
              await fetchReportingData<IWorkingHoursReportUsersDto>({
                path: '/reports/v1/workinghours?' + reportFiltersStr
              });

            const data = response?.data;
            setData(data);
          } catch (error) {
            console.error(
              'ActivTrak Error: Flexible Working Hours data failed to load' +
                error
            );
          } finally {
            setIsLoading(false);
          }
        },
        []
      );

      return { data, isLoading, init };
  };
