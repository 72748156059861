import environmentService from '_app/environmentService/environmentService.js';

angular.module('config', []).service('envConfig', envConfig);

/** @deprecated
 * Legacy envConfig used by AngularJS files
 * All other files should use the ApiDomainStore instead
 */
function envConfig() {
    this.adminApiUrl = function () {
        return environmentService.getAdminApiUrl();
    };

    this.apiUrl = function () {
        return environmentService.getApiUrl();
    };

    this.reportingServiceUrl = function () {
        return environmentService.getReportingServiceUrl();
    };

    this.identityServiceUrl = function () {
        return environmentService.getIdentityServiceUrl();
    };

    this.accountsApiUrl = function () {
        return environmentService.getAccountsApiUrl();
    };

    this.websocketUrl = function () {
        return environmentService.getWebsocketUrl();
    };

    this.websocketHttpsUrl = function () {
        return environmentService.getWebsocketHttpsUrl();
    };
}

angular.module('config').service('loginServiceUrl', loginServiceUrl);

/** @deprecated
 * Legacy envConfig used by AngularJS login process
 * All other files should use the ApiDomainStore instead
 */
function loginServiceUrl() {
    this.apiUrl = function () {
        return environmentService.getDefaultUrl();
    };

    this.authorizationServiceUrl = function () {
        return environmentService.getAuthorizationServiceUrl();
    };

    this.ssoAuthorizationUrl = function () {
        return environmentService.getSsoAuthorizationUrl();
    };
}

angular.module('config').service('envService', envService);

/** @deprecated
 * Legacy envConfig used by AngularJS files
 * All other files should use the ApiDomainStore instead
 */
function envService() {
    this.isProduction = function () {
        return environmentService.getEnvironmentName() === 'prod';
    };

    this.get = function () {
        return environmentService.getEnvironmentName();
    };

    this.getMarketoConfig = function () {
        return environmentService.getMarketoConfig();
    };

    this.ssoAuthorizationUrl = function () {
        return environmentService.getSsoAuthorizationUrl();
    };

    this.lookerUrl = function () {
        return environmentService.getLookerUrl();
    };

    this.signUpAppUrl = function () {
        return environmentService.getSignUpAppUrl();
    };
}
