import React from 'react';
import { getGoogleAccessToken } from '../third-party/googleAuthentication/googleAuthentication';
import { showModal } from '../stores/globalModalStore';
import { ExportErrorModalContent } from '../../_global/modals/components/ExportErrorModalContent';

export const fetchGoogleAccessToken = async (): Promise<string | null> => {
  let accessToken = null;

  try {
    accessToken = await getGoogleAccessToken();
  } catch (error) {
    console.error(
      'ActivTrak Error: Failed to get Google Drive access token:',
      error
    );
    showModal(<ExportErrorModalContent />);
    return null;
  }

  return accessToken;
};
