import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { ColDef } from 'ag-grid-community';

export type WorkingHoursReportProps = {
  refreshTimestamp: number;
  reportFilters: IReportFilters;
  calendarIntegrated: boolean;
};

export type WorkingHoursHeaderProps = {
  reportFilters: IReportFilters;
  isRefreshing: boolean;
  refreshTimestamp: number;
  onRefresh: () => void;
};

export type WorkingHoursReportNewGridHeaderProps = WorkingHoursHeaderProps & {
  selectedColumns: ColDef[];
  onSubmitColumns: (columns: ColDef[]) => void;
  onRestoreDefaults: () => void;
};

export type WorkingHoursReportWrapperProps = {
  isOfflineMeetingsIntegrationEnabled: boolean;
};

export interface IWorkingHoursReportUserDto {
  uniqueId: string;
  date: string; //date
  groups: string[];
  computers: string[];
  users: string[];
  firstActivity: string; //time
  lastActivity: string; //time
  lastActivityLog: string; //date
  productiveActiveTime: number;
  productivePassiveTime: number;
  unproductiveActiveTime: number;
  unproductivePassiveTime: number;
  undefinedActiveTime: number;
  undefinedPassiveTime: number;
  productiveTime: number;
  unproductiveTime: number;
  undefinedTime: number;
  activeTime: number;
  idleTime: number;
  totalTime: number;
  totalTimeOffline: number;
  location: string;
}
export interface IWorkingHoursReportUsersDto {
  data: IWorkingHoursReportUserDto[];
  total: number;
}

export interface IWorkingHoursReportState {
  data: IWorkingHoursReportUserDto[] | undefined;
  isLoading: boolean;
  init: (reportFiltersStr: string) => Promise<void>;
}

export enum WorkingHoursReportSections {
  SUMMARY = 'summary',
  DETAILED = 'detailed'
}

export type WhrColumnsProps = {
  view:
    | WorkingHoursReportSections.SUMMARY
    | WorkingHoursReportSections.DETAILED;
};

export type WorkingHoursTabProps = {
  view: WorkingHoursReportSections;
};
