import React, { createRef, MutableRefObject, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography
} from '@mui/material';
import { IInsightsCategory, ICoreCategory } from '../../models';
import { SearchBar } from '../../../common/components/SearchBar';
import { useCategoriesState } from '../../hooks/useCategoriesState';
import PercentInput from './PercentInput';
import CategoryCheckboxList from './CategoryCheckboxList';
import {
  BorderBoxDiv,
  CommonPaddingBox,
  DialogContentStyled,
  PillBoxText,
  StyledSearchBarContainer,
  StyledBoxContainer,
  TotCss
} from '../../styles/TimeOnTask.view.styles';
import DialogActionsBox from './DialogActionsBox';
import TextFieldContainer from './TextFieldContainer';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  editCoreCategoryRef: MutableRefObject<ICoreCategory>;
  onEditSaveConfiguration: () => void;
};

export const EditDialog = (props: ComponentProps) => {
  const { open, onClose, editCoreCategoryRef, onEditSaveConfiguration } = props;

  const { init, categories } = useCategoriesState();

  useEffect(() => {
    init();
  }, [init]);

  const [percentValue, setPercentValue] = useState<number>(null);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [filtered, setFiltered] = useState<IInsightsCategory[]>();
  const textInputRef = createRef<HTMLInputElement>();

  const [isCatsChanged, setIsCatsChanged] = useState<boolean>(false);
  const [isPercentChanged, setIsPercentChanged] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      if (!filtered) {
        setFiltered(categories);
      }
      setCheckedItems(
        editCoreCategoryRef.current.categories.map((cc) => cc.categoryId)
      );
      setPercentValue(editCoreCategoryRef.current.targetPercentage);
      setIsCatsChanged(false);
      setIsPercentChanged(false);
    }
  }, [categories, editCoreCategoryRef, filtered, open]);

  useEffect(() => {
    if (open && checkedItems) {
      const initialCategories = editCoreCategoryRef.current.categories.map(
        (cc) => cc.categoryId
      );
      setIsCatsChanged(
        JSON.stringify(initialCategories.sort()) !==
          JSON.stringify(checkedItems.sort())
      );
    }
  }, [open, checkedItems, editCoreCategoryRef]);

  const handleDropDownSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.toLowerCase();
    const matches = categories.filter(
      (x) => x.category.toLowerCase().indexOf(input) > -1
    );
    setFiltered(matches);
    setTimeout(() => event.target.focus(), 0);
  };

  const handlePercentBlur = (value: number | null) => {
    setPercentValue(value);
    setIsPercentChanged(value !== editCoreCategoryRef.current.targetPercentage);
  };

  const onSave = () => {
    editCoreCategoryRef.current.categories = checkedItems.map((item) => {
      return { categoryId: item, category: '' };
    });
    editCoreCategoryRef.current.targetPercentage = percentValue;
    onEditSaveConfiguration();
  };

  const close = () => {
    setFiltered(null);
    onClose();
  };

  return (
    open && (
      <Dialog open={open} onClose={close}>
        <CommonPaddingBox width={606} maxHeight={740}>
          <DialogTitle>Edit Activity Alignment</DialogTitle>
          <DialogContentStyled>
            <Box>
              <Typography sx={{ ...TotCss.editHeader, marginBottom: '10px' }}>
                Team
              </Typography>
              <BorderBoxDiv>
                <PillBoxText>
                  {editCoreCategoryRef.current.groupName}
                </PillBoxText>
              </BorderBoxDiv>
            </Box>
            <Box>
              <Typography sx={TotCss.editHeader}>Core Categories</Typography>
              <TextFieldContainer textValue={'Categories'} isHideable={false} />
            </Box>
            <StyledBoxContainer>
              <StyledSearchBarContainer>
                <SearchBar
                  placeholder={'Search Categories'}
                  fieldRef={textInputRef}
                  onClick={(e) => e.stopPropagation()}
                  handleDropDownSearchChange={(e) =>
                    handleDropDownSearchChange(e)
                  }
                />
              </StyledSearchBarContainer>
              <CategoryCheckboxList
                filtered={filtered}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
              />
            </StyledBoxContainer>
            <Box>
              <Typography sx={TotCss.editHeader}>
                Core Activity Time Goal
              </Typography>
              <PercentInput
                defaultValue={editCoreCategoryRef.current.targetPercentage}
                onBlur={handlePercentBlur}
              />
            </Box>
          </DialogContentStyled>
          <DialogActions>
            <DialogActionsBox
              close={close}
              onClick={onSave}
              disabled={
                (!isPercentChanged && !isCatsChanged) ||
                checkedItems.length === 0 ||
                !percentValue
              }
              confirmText={'Save configuration'}
            />
          </DialogActions>
        </CommonPaddingBox>
      </Dialog>
    )
  );
};
