import React from 'react';
import {
  addExport,
  fetchAllExportStatuses,
  fetchExportStatus,
  getCompletedExport,
  getErroredExport,
  getExportStore,
  getInProgressExport,
  resetExportStore,
  setExportStatusInterval
} from '../stores/exportStore';
import { showModal } from '../stores/globalModalStore';
import { ExportErrorModalContent } from '../../_global/modals/components/ExportErrorModalContent';
import { SaveExportModalContent } from '../../_global/modals/components/SaveExportModalContent';
import { ExportInitiatedModalContent } from '../../_global/modals/components/ExportInitiatedModalContent';
import { STATUS_POLLING_INTERVAL } from '../constants/exportStoreDefault';
import { ExportDestination } from '../enums/ExportDestination';
import { IExportReportParams, IExportRequest } from '../models/IExport';
import { createExport, deleteExport } from './exportStore.utils';
import { fetchGoogleAccessToken } from './googleAuth.utils';
import { ExportStatus } from '../enums/ExportStatus';

export const initializeExportStore = async () => {
  await fetchAllExportStatuses();
  const exportStatus =
    getInProgressExport() ?? getCompletedExport() ?? getErroredExport();
  if (exportStatus.status === ExportStatus.InProgress) {
    openExportInitiatedModal();
  }

  addExport(exportStatus);
  startExportStatusCheck(exportStatus.id);
};

export const startExportStatusCheck = async (exportId: string) => {
  clearExportStatusInterval(exportId);

  const exportStatusInterval = setInterval(async () => {
    try {
      const exportStatus = await fetchExportStatus(exportId);

      if (exportStatus.status !== ExportStatus.InProgress) {
        clearExportStatusInterval(exportId);
      }

      if (exportStatus.status === ExportStatus.Completed) {
        if (!exportStatus.downloadUrl) {
          throw new Error('Download URL not found.');
        }

        if (!exportStatus.id) {
          throw new Error('Download ID not found.');
        }

        if (!exportStatus.destination) {
          throw new Error('Download destination not found.');
        }

        showModal(<SaveExportModalContent exportStatus={exportStatus} />, true);
        return;
      }

      if (exportStatus.status === ExportStatus.Failed) {
        throw new Error('Export status of failed returned.');
      }
    } catch (error) {
      console.error('ActivTrak Error: Error polling export status', error);
      clearExportStatusInterval(exportId);
      deleteExport(exportId);
      showModal(<ExportErrorModalContent />);
      return;
    }
  }, STATUS_POLLING_INTERVAL);

  setExportStatusInterval(exportId, exportStatusInterval);
};

export const clearExportStatusInterval = (exportId: string) => {
  const { exportStatusIntervals } = getExportStore();
  const exportStatusInterval = exportStatusIntervals[exportId];
  clearInterval(exportStatusInterval);
  setExportStatusInterval(exportId, null);
};

export const clearAndResetExportStore = () => {
  const { exportStatusIntervals } = getExportStore();
  Object.values(exportStatusIntervals).forEach((interval) => {
    clearInterval(interval);
  });
  resetExportStore();
};

export const openExportInitiatedModal = () => {
  showModal(<ExportInitiatedModalContent />);
};

export const startExport = async (
  reportParams: IExportReportParams,
  exportDestination: ExportDestination
) => {
  const exportRequest: IExportRequest = {
    destination: exportDestination,
    googleDriveToken:
      exportDestination === ExportDestination.GoogleDrive
        ? await fetchGoogleAccessToken()
        : null,
    report: reportParams
  };

  try {
    const exportStatus = await createExport(exportRequest);

    addExport(exportStatus);
    startExportStatusCheck(exportStatus.id);
    openExportInitiatedModal();
  } catch (error) {
    console.error('ActivTrak Error: Failed to initiate export request:', error);
    showModal(<ExportErrorModalContent />);
  }
};
