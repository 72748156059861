import { useEffect, useState } from 'react';
import { Box, Divider, SxProps } from '@mui/material';
import {
  HeaderSC,
  ToolbarSC,
  TypographySC,
  TypographyTitleSC,
  TypographyPlanNameSC,
  FilledStarSC,
  HallowStarSC,
  LinkUserLicensesSC,
  MenuWrapper
} from '../styles/Header.styles';
import { HeaderMenu } from './HeaderMenu';
import { StatuspageProvider } from '../../../../../third-party/StatusPageProvider';
import { useAuthorization } from '../../../../services/Authorization';
import { HeaderButton } from './HeaderButton';
import { Role } from '../../../../enums';
import { getParsedLocalStorageItem, addBaseRoute } from '../../../../helpers';
import { Routes, localStorageItems } from '../../../../constants';
import { IAccountSettings, ICommandCenterToken } from '../../../../models';
import { ArrowCircleUp, Domain, Menu, SupportAgent } from '@mui/icons-material';
import {
  useSideBarPinnedStore,
  useRouteStore,
  isExcludedRoute,
  usePageTitleStore,
  getAvailableMenu
} from '../../../Navigation/navigationStore';
import {
  toggleFavorite,
  useFavoritesStore
} from '../../../Navigation/favoritesStore';
import { UNLIMITED_LICENSE_COUNT } from '../constants/HeaderConstants';
import authorizationService from '../../../../helpers/authorization';
import { BundleFlag } from '../../../../enums/BundleFlag';
import {
  getAccountPlanStore,
  isAccountPlanInTrial
} from '../../../../stores/accountPlanStore';
import { useImpersonate } from '../../../../hooks/useImpersonate';
import { useAccountSettingsStore } from '../../../../helpers/accountSettings/accountSettingsStore';
import { FeatureState } from '../../../../enums/FeatureState';
import FeatureBadge from '../../SideBar/FeatureBadge';

// Allows feature badge on subpages headings
const EA_BADGE_SUBPAGES = [];
const BETA_BADGE_SUBPAGES = ['app.settings.identity_id'];

export const Header = (props) => {
  const { hideNavigation } = props;
  const showPageTitleOnly = hideNavigation();

  const pageTitle = usePageTitleStore((s) => s.pageTitle);

  const { planTypeLabel, planRemainingDays } = getAccountPlanStore();
  const planName = planTypeLabel;
  const authService = useAuthorization();

  const accountSettings: IAccountSettings = useAccountSettingsStore(
    (s) => s.accountSettings
  );
  const daysRemainingInTrial = Math.ceil(planRemainingDays);
  const [userLicense, setUserLicense] = useState<string>('');

  const [restoreCommandCenterParent] = useImpersonate();

  //TODO: Temporarily rely on a backup token to show the link back to command center.
  const commandCenterBackupToken: ICommandCenterToken =
    getParsedLocalStorageItem(localStorageItems.commandCenterBackupToken);

  // TODO: Patching a triple stringified value:
  // FIXME: Need to figure out the source of the triple stringified token
  // In order to present the button is not required to validate the token as long
  // as it exists - instead checking for not empty object.
  // DONT TRY THIS AT HOME!

  const hasCCBackupToken =
    !!commandCenterBackupToken &&
    JSON.stringify(commandCenterBackupToken) !== '{}';

  const isSupportUserLoggedIntoCCAccessAccount = Boolean(
    authService.hasAnyRole([Role.SupportAdvanced, Role.SuperAdmin]) &&
      accountSettings.roles &&
      accountSettings.roles.find(function (key) {
        return key.toUpperCase() === Role.CommandCenter.toUpperCase();
      })
  );

  const isCCAccessAccount = Boolean(
    authService.hasAnyRole([Role.CommandCenter])
  );

  const isCCEnterpriseAccessAccount = Boolean(
    authService.hasAnyRole([Role.CommandCenterEnterprise])
  );
  const showCommandCenterButton =
    isCCAccessAccount ||
    isSupportUserLoggedIntoCCAccessAccount ||
    hasCCBackupToken;
  const showSupportPortalButton = authService.isSupportUser();
  const showUpgradeButton = Boolean(
    authService.pageAuthorization(Routes.Upgrade)
  );

  //[Start]: Favorites Integration
  const [filled, setFilled] = useState(false);
  const { favorites, isInFavorites } = useFavoritesStore((s) => s);
  const isSideBarPinned = useSideBarPinnedStore((s) => s.isSideBarPinned);
  const setIsSideBarPinned = useSideBarPinnedStore((s) => s.setIsSideBarPinned);

  const route = useRouteStore((s) => s.route);
  const getFeatureBadge = (routeName) => {
    const availableMenu = getAvailableMenu(routeName);

    if (availableMenu) {
      if (availableMenu.beta) {
        return FeatureState.Beta;
      } else if (availableMenu.earlyAccess) {
        return FeatureState.EarlyAccess;
      }
    }

    if (EA_BADGE_SUBPAGES.includes(routeName)) {
      return FeatureState.EarlyAccess;
    }

    if (BETA_BADGE_SUBPAGES.includes(routeName)) {
      return FeatureState.Beta;
    }
  };

  const featureBadge = getFeatureBadge(route?.name);

  useEffect(() => {
    const { usedLicenses = 0, totalLicenses = 0 } = accountSettings;

    setUserLicense(
      `Users / Licenses: ${usedLicenses}${
        totalLicenses === UNLIMITED_LICENSE_COUNT ? '' : '/' + totalLicenses
      }`
    );
  }, [accountSettings]);

  useEffect(() => {
    const status = isInFavorites({ name: route.name });
    setFilled(() => status);
  }, [route, favorites, isInFavorites]);

  const hasFavoriteNavigation =
    !isExcludedRoute(route?.name) &&
    authorizationService.hasFeature(BundleFlag.FavoriteNavigation);
  //[End]: Favorites Integration

  const openSidebar = () => {
    setIsSideBarPinned(!isSideBarPinned);
  };

  const hideOnSmallScreenStyle: SxProps = {
    display: { xs: 'none', sm: 'none', md: 'inline-flex' }
  };

  const hideOnLargeScreenStyle: SxProps = {
    display: { xs: 'inline-flex', sm: 'inline-flex', md: 'none' }
  };

  const isTrialOrFree: () => boolean = () => {
    return (
      isAccountPlanInTrial() ||
      !authorizationService.hasFeature(BundleFlag.PaidFunctionality)
    );
  };

  const hideAccountConfiguration = !authService?.hasAnyRole([
    Role.Admin,
    Role.SupportBasic,
    Role.SuperAdmin
  ]);

  return (
    <HeaderSC maxWidth={false} className="header hide-on-print">
      <ToolbarSC disableGutters>
        {showPageTitleOnly ? (
          <TypographyTitleSC>
            <strong>{pageTitle}</strong>
          </TypographyTitleSC>
        ) : (
          <>
            <MenuWrapper>
              <Menu
                onClick={openSidebar}
                sx={{
                  margin: '5px 0px 0px 5px',
                  padding: '5px'
                }}
              />
            </MenuWrapper>
            <Box sx={{ flexGrow: 4, display: 'inline-flex' }}>
              <TypographyTitleSC>
                <strong>{pageTitle}</strong>
              </TypographyTitleSC>
              {featureBadge && <FeatureBadge label={featureBadge} />}
              {hasFavoriteNavigation && (
                <TypographySC
                  onClick={async () => {
                    await toggleFavorite(route, 'header');
                  }}
                  id="19d850ec-30df-4a94-9fab-13ddd0c542f0"
                  variant="body2"
                  sx={hideOnLargeScreenStyle}
                >
                  {filled ? (
                    <FilledStarSC fontSize="small" />
                  ) : (
                    <HallowStarSC fontSize="small" />
                  )}
                </TypographySC>
              )}
            </Box>
            {hasFavoriteNavigation && (
              <>
                <TypographySC
                  onClick={async () => {
                    await toggleFavorite(route, 'header');
                  }}
                  id="19d850ec-30df-4a94-9fab-13ddd0c542f0"
                  variant="body2"
                  sx={hideOnSmallScreenStyle}
                >
                  {filled ? (
                    <>
                      Remove Favorite <FilledStarSC fontSize="small" />
                    </>
                  ) : (
                    <>
                      Add Favorite <HallowStarSC fontSize="small" />
                    </>
                  )}
                </TypographySC>
                <Divider
                  orientation="vertical"
                  sx={{
                    ...hideOnSmallScreenStyle,
                    borderColor: 'rgba(68, 68, 68, 0.8)',
                    height: '20px'
                  }}
                />
              </>
            )}
            {showSupportPortalButton && (
              <>
                <LinkUserLicensesSC
                  href={addBaseRoute(Routes.UserAgents)}
                  underline="none"
                >
                  {userLicense}
                </LinkUserLicensesSC>
                <Divider
                  orientation="vertical"
                  sx={{
                    ...hideOnSmallScreenStyle,
                    borderColor: 'rgba(68, 68, 68, 0.8)',
                    height: '20px'
                  }}
                />
              </>
            )}
            <TypographyPlanNameSC variant="body2" sx={hideOnSmallScreenStyle}>
              {planName}
            </TypographyPlanNameSC>
            {showUpgradeButton && !isCCEnterpriseAccessAccount && (
              <HeaderButton
                route={`/#/app${Routes.Upgrade}`}
                color={isTrialOrFree() ? 'secondary' : 'primary'}
                sx={hideOnSmallScreenStyle}
                id="072a853e-70f0-4fc0-8040-c1b03fd269c0"
              >
                <ArrowCircleUp sx={{ marginRight: '8px' }} fontSize="small" />
                {isTrialOrFree() ? 'Upgrade' : 'Add Licenses'}
                {daysRemainingInTrial
                  ? ` - ${daysRemainingInTrial} days left`
                  : ''}
              </HeaderButton>
            )}
            {isAccountPlanInTrial() &&
              !showUpgradeButton &&
              !isCCEnterpriseAccessAccount && (
                <>
                  <Divider
                    orientation="vertical"
                    sx={{
                      ...hideOnSmallScreenStyle,
                      borderColor: 'rgba(68, 68, 68, 0.8)',
                      height: '20px'
                    }}
                  />
                  <TypographyPlanNameSC
                    variant="body2"
                    sx={hideOnSmallScreenStyle}
                  >
                    {`${
                      daysRemainingInTrial > 0 ? daysRemainingInTrial : 0
                    } days left`}
                  </TypographyPlanNameSC>
                </>
              )}
            {showSupportPortalButton && (
              <HeaderButton
                route={Routes.SupportPortal}
                sx={hideOnSmallScreenStyle}
              >
                <SupportAgent sx={{ marginRight: '8px' }} fontSize="small" />
                Support Portal
              </HeaderButton>
            )}
            {showCommandCenterButton && (
              <HeaderButton
                onClick={(e) => {
                  e.preventDefault();
                  (async () => {
                    await restoreCommandCenterParent();
                    window.location.href = '/#/cc/dashboard';
                  })();
                }}
                sx={hideOnSmallScreenStyle}
              >
                <Domain sx={{ marginRight: '8px' }} fontSize="small" /> Command
                Center
              </HeaderButton>
            )}
            <StatuspageProvider>
              <HeaderMenu
                {...props}
                userLicense={userLicense}
                accountSettings={accountSettings}
                showCommandCenterButton={showCommandCenterButton}
                showSupportPortalButton={showSupportPortalButton}
                showUpgradeButton={showUpgradeButton}
                hideOnLargeScreenStyle={hideOnLargeScreenStyle}
                hideAccountConfiguration={hideAccountConfiguration}
              />
            </StatuspageProvider>
          </>
        )}
      </ToolbarSC>
    </HeaderSC>
  );
};
