import React from 'react';
import FileDownload from '@mui/icons-material/FileDownload';
import Google from '@mui/icons-material/Google';
import { ExportDestination } from '../../../enums/ExportDestination';

export interface IExportDestinationOption {
  key: ExportDestination;
  label: string;
  icon: JSX.Element;
  destinationId?: string;
}

export const exportDestinationOptions: IExportDestinationOption[] = [
  {
    key: ExportDestination.GCS,
    label: 'Download',
    icon: <FileDownload />,
    destinationId: 'device'
  },
  {
    key: ExportDestination.GoogleDrive,
    label: 'Save to Google',
    icon: <Google />,
    destinationId: 'google'
  }
];
